import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PromotionsDisplay.css';
import CountdownTimer from './CountdownTimer';
import ImageModal from './ImageModal';

const PromotionsDisplay = () => {
    const [promotions, setPromotions] = useState({ promotionalBundles: [], promotionalPackages: [] });
    const [categorizedPromotions, setCategorizedPromotions] = useState({});
    const [currentIndices, setCurrentIndices] = useState({});
    const [categoryViewStates, setCategoryViewStates] = useState({});
    const [upcomingPromotions, setUpcomingPromotions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    useEffect(() => {
        fetchPromotions();
        fetchUpcomingPromotions();
    }, []);

    const fetchPromotions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-display/promotions`);
           // console.log('All promotions:', JSON.stringify(response.data, null, 2));
            setPromotions(response.data);
            separatePromotions(response.data);
        } catch (error) {
            console.error("Error fetching promotions:", error);
        }
    };

    const fetchUpcomingPromotions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-display/upcoming-promotions`);
            setUpcomingPromotions(response.data);
        } catch (error) {
            console.error("Error fetching upcoming promotions:", error);
        }
    };

    const separatePromotions = (data) => {
        const categorized = {};
        const viewStates = {};
        const indices = {};
        
        const processItems = (items, type) => {
            items.forEach(promo => {
                const categoryId = promo.promotion_category_id;
                if (!categorized[categoryId]) {
                    categorized[categoryId] = {
                        bundles: [],
                        packages: [],
                        categoryDetails: promo.PromotionCategory,
                        hasPackages: false,
                        hasBundles: false,
                        defaultBundleIndex: -1,
                        defaultPackageIndex: -1
                    };
                    // Initialize view state based on what's available
                    viewStates[categoryId] = true; // Default to bundles
                }
                if (type === 'bundle') {
                    categorized[categoryId].hasBundles = true;
                    categorized[categoryId].bundles.push(promo);
                } else {
                    categorized[categoryId].hasPackages = true;
                    categorized[categoryId].packages.push(promo);
                }
            });
        };

        processItems(data.promotionalBundles, 'bundle');
        processItems(data.promotionalPackages, 'package');

        // Sort and set indices
        Object.keys(categorized).forEach(categoryId => {
            const category = categorized[categoryId];
            
            // Sort both arrays
            category.bundles.sort((a, b) => a.bundle_priority - b.bundle_priority);
            category.packages.sort((a, b) => a.package_priority - b.package_priority);
            
            // Set default indices
            category.defaultBundleIndex = category.bundles.findIndex(b => b.promo_bundle_default);
            category.defaultPackageIndex = category.packages.findIndex(p => p.promo_package_default);
            
            // Set initial index based on what's available
            if (category.hasBundles) {
                indices[categoryId] = category.defaultBundleIndex >= 0 ? category.defaultBundleIndex : 0;
                viewStates[categoryId] = true;
            } else if (category.hasPackages) {
                indices[categoryId] = category.defaultPackageIndex >= 0 ? category.defaultPackageIndex : 0;
                viewStates[categoryId] = false;
            }
        });

        setCurrentIndices(indices);
        setCategorizedPromotions(categorized);
        setCategoryViewStates(viewStates);
    };

    const renderPromoCard = (promo, isFireSale = false) => {
        if (!promo) return null;
        
        const isBundle = 'bundle_id' in promo;
        const providerCategoryImageUrl = promo.Package?.ProviderCategory?.prov_cat_image_url || promo.ProviderCategory?.prov_cat_image_url;
        const providerCategoryName = promo.Package?.ProviderCategory?.prov_cat_name || promo.ProviderCategory?.prov_cat_name;
        const endDate = new Date(promo.active_until);
        const promotionalPrice = isBundle ? promo.package_price : promo.sell_price_incl;
        const originalPrice = isBundle ? 
            promo.Package?.sell_price_incl : 
            promo.original_price || promo.Package?.sell_price_incl;
        const installationPrice = isBundle ? promo.installation_price : promo.installation;
        const originalInstallation = promo.Package?.installation;

        return (
            <div key={isBundle ? promo.bundle_id : promo.package_id} className="card mb-3 text-center noselect">
                {providerCategoryImageUrl && <img src={process.env.REACT_APP_IMAGE_URL + '/' + providerCategoryImageUrl} alt="Provider" className="card-img-top" />}
                <div className="card-body">
                    <div className="timer-container">
                        <h4 className="mt-3 mb-3">Ends in:<CountdownTimer endDate={endDate} /> </h4>
                    </div>
                    <h5 className="card-title">{isBundle ? promo.bundle_name : promo.package_name}</h5>
                    
                    <h4 className="card-text speeds-container">
                        <div className="speed-item">
                            <i className="fa-solid fa-cloud-arrow-down"></i> {promo.Package?.download_speed_mb || promo.download_speed_mb}Mbps
                        </div>
                        <div className="speed-item">
                            <i className="fa-solid fa-cloud-arrow-up"></i> {promo.Package?.upload_speed_mb || promo.upload_speed_mb}Mbps
                        </div>
                    </h4>
                    
                    <p className="card-text">
                        <i className="fa-solid fa-wrench"></i> Installation:  
                        {(installationPrice === '0.00' || installationPrice === 0) ? (
                            <span className="promotional-price"> Free!</span>
                        ) : (
                            <>
                                {originalInstallation && parseFloat(installationPrice) < parseFloat(originalInstallation) && (
                                    <span className="strike-through"><del> R{originalInstallation}</del> </span>
                                )}
                                <span className={
                                    originalInstallation && parseFloat(installationPrice) < parseFloat(originalInstallation) 
                                        ? "promotional-price" 
                                        : ""
                                }>
                                    <span> R{installationPrice}</span>
                                </span>
                            </>
                        )}
                    </p>
                   
                    <p className="card-text">
                        <i className="fa-solid fa-calendar-alt"></i> Term: {isBundle ? promo.Package?.term : promo.Package?.term} months
                    </p>
                    {((isBundle && promo.bundle_description) || (!isBundle && promo.package_description)) && (
                        <div className="card-text mb-3">
                            <i className="fa-solid fa-info-circle"></i> {isBundle ? promo.bundle_description : promo.package_description}
                        </div>
                    )}

{((isBundle && promo.ValueAddedProducts && promo.ValueAddedProducts.length > 0) || 
                      (!isBundle && promo.Package?.ValueAddedProducts && promo.Package.ValueAddedProducts.length > 0)) && (
                        <div className="mt-3">
                            <h6>Included Products:</h6>
                            {(isBundle ? promo.ValueAddedProducts : promo.Package.ValueAddedProducts).map((product) => (
                                <div key={product.vap_id} className="d-flex align-items-center justify-content-center mb-2">
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + product.vap_image_url} 
                                        alt={product.vap_name} 
                                        className="mr-2 cursor-pointer"
                                        style={{width: '50px', height: '50px', objectFit: 'contain'}}
                                        onClick={() => {
                                            setSelectedImage({
                                                url: process.env.REACT_APP_IMAGE_URL + '/' + product.vap_image_url,
                                                name: product.vap_name
                                            });
                                            setShowModal(true);
                                        }}
                                    />
                                    <div>
                                        <p className="mb-0">
                                            {isBundle ? `${product.BundleValueAddedProduct.quantity} x ` : ''}
                                            {product.vap_name}
                                        </p>
                                        <p className="mb-0">
                                            {isBundle ? (
                                                product.BundleValueAddedProduct && product.BundleValueAddedProduct.price !== undefined ? (
                                                    product.BundleValueAddedProduct.price === '0.00' ? (
                                                        <span className="text-danger">Free!</span>
                                                    ) : (
                                                        <>
                                                            {parseFloat(product.BundleValueAddedProduct.price) < parseFloat(product.vap_sell) && (
                                                                <span className="strike-through"><del>R{product.vap_sell}</del> </span>
                                                            )}
                                                            <span className="promotional-price">R{product.BundleValueAddedProduct.price} purchase</span>
                                                        </>
                                                    )
                                                ) : (
                                                    <span>R{product.vap_sell}</span>
                                                )
                                            ) : (
                                                product.PromotionalPackageVAP && product.PromotionalPackageVAP.price !== undefined ? (
                                                    product.PromotionalPackageVAP.price === '0.00' ? (
                                                        <span className="text-danger">Free!</span>
                                                    ) : (
                                                        <>
                                                            {parseFloat(product.PromotionalPackageVAP.price) < parseFloat(product.vap_sell) && (
                                                                <span className="strike-through"><del>R{product.vap_sell}</del> </span>
                                                            )}
                                                            <span className="promotional-price">R{product.PromotionalPackageVAP.price}</span>
                                                        </>
                                                    )
                                                ) : (
                                                    <span>R{product.vap_sell}</span>
                                                )
                                            )}
                                        </p>
                                        
                                    </div>
                                    
                                </div>
                            ))}
                            
                        </div>
                    )}



                    <h3 className="card-title">
                        {parseFloat(promotionalPrice) < parseFloat(originalPrice) && (
                            <span className="text-muted"><del>R{originalPrice}</del> </span>
                        )}
                        R{promotionalPrice} p/m
                    </h3>
                    <a 
                        href={`/coverage?promo_${isBundle ? 'bundle' : 'package'}_id=${isBundle ? promo.bundle_id : promo.promo_package_id}`} 
                        className="btn btn-trusc-primary mt-3"
                    >
                        Sign Up
                    </a>
                </div>
            </div>
        );
    };

    const handleNext = (categoryId) => {
        const items = categoryViewStates[categoryId] 
            ? categorizedPromotions[categoryId].bundles 
            : categorizedPromotions[categoryId].packages;
        
        const currentIndex = currentIndices[categoryId];
        const nextIndex = currentIndex + 1;
        
        if (nextIndex < items.length) {
            setCurrentIndices(prev => ({
                ...prev,
                [categoryId]: nextIndex
            }));
        }
    };

    const handlePrev = (categoryId) => {
        const currentIndex = currentIndices[categoryId];
        const prevIndex = currentIndex - 1;
        
        if (prevIndex >= 0) {
            setCurrentIndices(prev => ({
                ...prev,
                [categoryId]: prevIndex
            }));
        }
    };

    const toggleView = (categoryId) => {
        setCategoryViewStates(prev => {
            const newState = {
                ...prev,
                [categoryId]: !prev[categoryId]
            };
            
            // Get the category data
            const category = categorizedPromotions[categoryId];
            const newViewIsBundles = !prev[categoryId];
            
            // Set index to default item or 0 when switching views
            setCurrentIndices(prevIndices => ({
                ...prevIndices,
                [categoryId]: newViewIsBundles 
                    ? (category.defaultBundleIndex >= 0 ? category.defaultBundleIndex : 0)
                    : (category.defaultPackageIndex >= 0 ? category.defaultPackageIndex : 0)
            }));
            
            return newState;
        });
    };

    const renderUpcomingPromotion = (category) => {
        return (
            <div key={category.promotion_category_id} className="promotion-category-banner mb-1">
                <h2 
                    className="promotion-category-title"
                    style={{
                        backgroundColor: category.promotion_category_header_background_color,
                        color: category.promotion_category_header_text_color
                    }}
                >
                    {category.promotion_category_name} (Coming Soon)
                </h2>
                {category.promotion_category_description && (
                    <div 
                        className="promotion-category-description text-center mb-1"
                        style={{
                            padding: '10px',
                            fontSize: '1.1rem'
                        }}
                    >
                        {category.promotion_category_description}
                    </div>
                )}
                <div className="text-center mt-3 mb-3">
                    <h4>Starts in:</h4>
                    <h3><CountdownTimer endDate={new Date(category.earliest_start_date)} /></h3>
                </div>
            </div>
        );
    };

    return (
        <div className="container mt-4">
            {[...Object.entries(categorizedPromotions).map(([key, category]) => ({
                type: 'active',
                key,
                data: category,
                priority: category.categoryDetails.promotion_category_priority
            })), 
            ...upcomingPromotions.map(promo => ({
                type: 'upcoming',
                key: `upcoming-${promo.promotion_category_id}`,
                data: promo,
                priority: promo.promotion_category_priority
            }))]
            .sort((a, b) => a.priority - b.priority)
            .map(item => {
                if (item.type === 'upcoming') {
                    return renderUpcomingPromotion(item.data);
                }
                
                return (
                    <div key={item.key} className="promotion-category-banner mb-1">
                        <h2 
                            className="promotion-category-title"
                            style={{
                                backgroundColor: item.data.categoryDetails.promotion_category_header_background_color,
                                color: item.data.categoryDetails.promotion_category_header_text_color
                            }}
                        >
                            {item.data.categoryDetails.promotion_category_name}
                        </h2>
                        {item.data.categoryDetails.promotion_category_description && (
                            <div 
                                className="promotion-category-description text-center mb-1"
                                style={{
                                    padding: '10px',
                                    fontSize: '1.1rem'
                                }}
                            >
                                {item.data.categoryDetails.promotion_category_description}
                            </div>
                        )}
                        
                        {item.data.hasBundles && item.data.hasPackages && (
                            <>
                                <div className="toggle-buttons mb-2">
                                    <button 
                                        className={`btn ${categoryViewStates[item.key] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`}
                                        onClick={() => toggleView(item.key)}
                                    >
                                        Bundles
                                    </button>
                                    <button 
                                        className={`btn ${!categoryViewStates[item.key] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`}
                                        onClick={() => toggleView(item.key)}
                                    >
                                        Packages
                                    </button>
                                </div>
                                <p className="text-center mb-3">
                                    Currently showing: {categoryViewStates[item.key] ? 'Bundles' : 'Packages'}
                                </p>
                            </>
                        )}

                        {((categoryViewStates[item.key] ? item.data.bundles : item.data.packages) || []).length > 0 ? (
                            <div className="d-flex align-items-center justify-content-center">
                                {((categoryViewStates[item.key] ? item.data.bundles : item.data.packages) || []).length > 1 && (
                                    <button 
                                        className="btn btn-trusc-carousel mr-3" 
                                        onClick={() => handlePrev(item.key)}
                                        disabled={currentIndices[item.key] === 0}
                                    >
                                        &lt;
                                    </button>
                                )}
                                <div className="flex-grow-1" style={{maxWidth: '500px'}}>
                                    {renderPromoCard(
                                        (categoryViewStates[item.key] ? item.data.bundles : item.data.packages)[currentIndices[item.key]], 
                                        item.data.categoryDetails.promotion_category_name === "Fire Sale"
                                    )}
                                </div>
                                {((categoryViewStates[item.key] ? item.data.bundles : item.data.packages) || []).length > 1 && (
                                    <button 
                                        className="btn btn-trusc-carousel ml-3" 
                                        onClick={() => handleNext(item.key)}
                                        disabled={currentIndices[item.key] === 
                                            (categoryViewStates[item.key] 
                                                ? item.data.bundles.length - 1 
                                                : item.data.packages.length - 1)}
                                    >
                                        &gt;
                                    </button>
                                )}
                            </div>
                        ) : (
                            <p className="text-center">No {categoryViewStates[item.key] ? 'bundles' : 'packages'} available</p>
                        )}
                    </div>
                );
            })}
            <ImageModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                imageUrl={selectedImage.url}
                altText={selectedImage.name}
            />
        </div>
    );
};

export default PromotionsDisplay;
