import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PackagesDisplay.css';
import SignUpForm from './SignUpForm.js';
import ImageModal from './ImageModal';

const InstallationPrice = ({ basePrice, promoPrice }) => {
  // Handle case where promoPrice is undefined
  if (!promoPrice) return null;

  // Handle free installation with possible base price
  if (parseFloat(promoPrice) === 0) {
    if (basePrice && parseFloat(basePrice) > 0) {
      return (
        <span className="installation-price">
          <span className="strike-through">R{basePrice}</span>
          <span style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>
          <span className="cost-post-text"></span>
        </span>
      );
    }
    return <span className="installation-price" style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>;
  }

  // Show strike-through only if basePrice exists and is different from promoPrice
  if (basePrice && basePrice !== promoPrice) {
    return (
      <span className="installation-price">
        <span className="strike-through">R{basePrice}</span>
        <span className="promotional-price">R{promoPrice}</span>
        <span className="cost-post-text"></span>
      </span>
    );
  }

  // Default case - just show the promoPrice
  return (
    <span className="installation-price">
      R{promoPrice}<span className="cost-post-text"></span>
    </span>
  );
};

const PackagesDisplay = () => {
    const location = useLocation();
    const coverageData = location.state?.coverageData;

    const [data, setData] = useState(null);
    const [selectedTechClass, setSelectedTechClass] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [sortedPackages, setSortedPackages] = useState([]);
    const [sortedBundles, setSortedBundles] = useState([]);
    const [filteredPackages, setFilteredPackages] = useState([]);
    const [filteredBundles, setFilteredBundles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [viewBundles, setViewBundles] = useState(true);
    const [techClassIndex, setTechClassIndex] = useState(0);
    const [providerIndex, setProviderIndex] = useState(0);
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [selectedPackageOrBundle, setSelectedPackageOrBundle] = useState(null);
    const [gpsCoordinates, setGpsCoordinates] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [notFoundMessage, setNotFoundMessage] = useState('');
    const [noServicesMessage, setNoServicesMessage] = useState('');
    const [currentIndices, setCurrentIndices] = useState({});
    const [categoryViewStates, setCategoryViewStates] = useState({});
    const [showPromotions, setShowPromotions] = useState(true);
    const [promotionViewStates, setPromotionViewStates] = useState({});
    const [promotionIndices, setPromotionIndices] = useState({});
    const [groupedPromotions, setGroupedPromotions] = useState({});
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', alt: '' });

    const providerRef = useRef(null);
    const packagesRef = useRef(null);

    useEffect(() => {
        if (coverageData) {
            if (coverageData.error === "No services found in the response") {
                setNoServicesMessage("No services are currently available at your location.");
            } else {
                const sortedTechClasses = coverageData.technology_classes.sort((a, b) => a.tech_class_priority - b.tech_class_priority);
                setData({...coverageData, technology_classes: sortedTechClasses});
                setLatitude(coverageData.coverageCheck.latitude);
                setLongitude(coverageData.coverageCheck.longitude);
                
                const techClassId = location.state?.techClassId;
                if (techClassId) {
                    const selectedTech = sortedTechClasses.find(tech => tech.tech_class_id === parseInt(techClassId));
                    if (selectedTech) {
                        setSelectedTechClass(selectedTech);
                    } else {
                        setNoServicesMessage(`The selected technology class is not available at this address. Please check our other options below.`);
                        setSelectedTechClass(sortedTechClasses[0]);
                    }
                } else if (sortedTechClasses.length > 0) {
                    setSelectedTechClass(sortedTechClasses[0]);
                } else {
                    setNoServicesMessage("No services are currently available at your location.");
                }
                
                setSelectedId(location.state?.selectedId);
                setSelectedType(location.state?.selectedType);
            }
        }
    }, [coverageData, location.state]);

    useEffect(() => {
        if (selectedTechClass) {
            const sortedProviders = selectedTechClass.providers.sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            setSelectedProvider(sortedProviders[0]);
            setProviderIndex(0);
        }
    }, [selectedTechClass]);

    useEffect(() => {
        if (selectedProvider && selectedProvider.provider_categories) {
            const viewStates = {};
            const indices = {};
            
            selectedProvider.provider_categories.forEach(providerCategory => {
                if (!providerCategory) return;
                
                const bundles = providerCategory.bundles || [];
                const packages = providerCategory.packages || [];
                
                // Only set states if we have valid data
                if (providerCategory.prov_cat_id) {
                    // Default to bundles if available, otherwise packages
                    viewStates[providerCategory.prov_cat_id] = bundles.length > 0;
                    
                    // Set initial index based on the view state
                    if (viewStates[providerCategory.prov_cat_id]) {
                        const defaultBundleIndex = bundles.findIndex(b => b.bundle_default === true);
                        indices[providerCategory.prov_cat_id] = defaultBundleIndex >= 0 ? defaultBundleIndex : 0;
                    } else {
                        const defaultPackageIndex = packages.findIndex(p => p.package_default === true);
                        indices[providerCategory.prov_cat_id] = defaultPackageIndex >= 0 ? defaultPackageIndex : 0;
                    }
                }
            });
            
            setCurrentIndices(indices);
            setCategoryViewStates(viewStates);
        }
    }, [selectedProvider]);

    useEffect(() => {
        if (selectedId && selectedType && data) {
            const findItem = (items) => items.find(item => item[`${selectedType}_id`] == selectedId);
            
            let itemFound = false;
            for (const techClass of data.technology_classes) {
                for (const provider of techClass.providers) {
                    for (const category of provider.provider_categories) {
                        let item;
                        switch (selectedType) {
                            case 'package':
                                item = findItem(category.packages);
                                break;
                            case 'bundle':
                                item = findItem(category.bundles);
                                break;
                            case 'promo_package':
                                item = findItem(category.promotional_packages);
                                break;
                            case 'promo_bundle':
                                item = findItem(category.promotional_bundles);
                                break;
                        }
                        if (item) {
                            setSelectedTechClass(techClass);
                            setSelectedProvider(provider);
                            setSelectedPackageOrBundle(item);
                            setShowSignUpForm(true);
                            itemFound = true;
                            return;
                        }
                    }
                }
            }
            
            if (!itemFound) {
                setNotFoundMessage("<strong style='font-size: 1.2em;'>The selected product is not available at your location.<br><br>Please select a product that we do have available from the list below.</strong>");
                // Set the first available tech class and provider
                if (data.technology_classes.length > 0) {
                    setSelectedTechClass(data.technology_classes[0]);
                    if (data.technology_classes[0].providers.length > 0) {
                        setSelectedProvider(data.technology_classes[0].providers[0]);
                    }
                }
            }
        }
    }, [selectedId, selectedType, data]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [selectedProvider]);

    useEffect(() => {
        if (data && data.technology_classes) {
            const newGroupedPromotions = {};
            data.technology_classes.forEach(tc => {
                tc.providers.forEach(provider => {
                    provider.provider_categories.forEach(category => {
                        const promotions = {
                            promotional_bundles: category.promotional_bundles || [],
                            promotional_packages: category.promotional_packages || []
                        };

                        [...promotions.promotional_bundles, ...promotions.promotional_packages]
                            .filter(promo => promo && promo.promotion_category_id)
                            .forEach(promo => {
                                const categoryId = promo.promotion_category_id;
                                if (!newGroupedPromotions[categoryId]) {
                                    newGroupedPromotions[categoryId] = {
                                        categoryDetails: promo.PromotionCategory,
                                        bundles: [],
                                        packages: []
                                    };
                                }
                                if ('bundle_name' in promo) {
                                    newGroupedPromotions[categoryId].bundles.push(promo);
                                } else {
                                    newGroupedPromotions[categoryId].packages.push(promo);
                                }
                            });
                    });
                });
            });
            setGroupedPromotions(newGroupedPromotions);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const promotionalItems = getPromotionalItemsByCategory();
            const initialViewStates = {};
            const initialIndices = {};
            
            promotionalItems.forEach(promoCategory => {
                const categoryId = promoCategory.promotion_category_id;
                
                // Set initial view state to bundles if they exist, packages otherwise
                initialViewStates[categoryId] = promoCategory.bundles.length > 0;
                
                // Find default promotional items
                const defaultBundleIndex = promoCategory.bundles.findIndex(b => b.bundle_default === true || b.promo_bundle_default === true);
                const defaultPackageIndex = promoCategory.packages.findIndex(p => p.package_default === true || p.promo_package_default === true);
                
                // Set initial index based on what's available and has defaults
                if (promoCategory.bundles.length > 0) {
                    initialIndices[categoryId] = defaultBundleIndex >= 0 ? defaultBundleIndex : 0;
                } else if (promoCategory.packages.length > 0) {
                    initialIndices[categoryId] = defaultPackageIndex >= 0 ? defaultPackageIndex : 0;
                }
            });

            setPromotionViewStates(initialViewStates);
            setPromotionIndices(initialIndices);
        }
    }, [data]);

    const handlePrevTechClass = () => {
        if (data && data.technology_classes && techClassIndex > 0) {
            const sortedClasses = [...data.technology_classes].sort((a, b) => a.tech_class_priority - b.tech_class_priority);
            const prevIndex = techClassIndex - 1;
            setTechClassIndex(prevIndex);
            setSelectedTechClass(sortedClasses[prevIndex]);
        }
    };

    const handleNextTechClass = () => {
        if (data && data.technology_classes && techClassIndex < data.technology_classes.length - 1) {
            const sortedClasses = [...data.technology_classes].sort((a, b) => a.tech_class_priority - b.tech_class_priority);
            const nextIndex = techClassIndex + 1;
            setTechClassIndex(nextIndex);
            setSelectedTechClass(sortedClasses[nextIndex]);
        }
    };

    const handlePrevProvider = () => {
        if (selectedTechClass && selectedTechClass.providers && providerIndex > 0) {
            const sortedProviders = [...selectedTechClass.providers].sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            const prevIndex = providerIndex - 1;
            setProviderIndex(prevIndex);
            setSelectedProvider(sortedProviders[prevIndex]);
        }
    };

    const handleNextProvider = () => {
        if (selectedTechClass && selectedTechClass.providers && providerIndex < selectedTechClass.providers.length - 1) {
            const sortedProviders = [...selectedTechClass.providers].sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            const nextIndex = providerIndex + 1;
            setProviderIndex(nextIndex);
            setSelectedProvider(sortedProviders[nextIndex]);
        }
    };

    const handleSignUp = (packageData) => {
        // Check for matching promotion first
        const providerCategory = selectedProvider.provider_categories.find(cat => 
            cat.packages.some(p => p.package_id === packageData.package_id)
        );
        
        const matchingPromo = findMatchingPromotion(packageData.package_id, providerCategory, false);
        
        // Use promotional package if available, otherwise use regular package
        setSelectedPackageOrBundle(matchingPromo ? matchingPromo.item : packageData);
        setShowSignUpForm(true);
    };

    const handleBundleSignUp = (bundleData) => {
        // Check for matching promotion first
        const providerCategory = selectedProvider.provider_categories.find(cat => 
            cat.bundles.some(b => b.package_id === bundleData.package_id)
        );
        
        const matchingPromo = findMatchingPromotion(bundleData.package_id, providerCategory, true);
        
        // Use promotional bundle if available, otherwise use regular bundle
        setSelectedPackageOrBundle(matchingPromo ? matchingPromo.item : bundleData);
        setShowSignUpForm(true);
    };

    const handleCloseSignUpForm = () => {
        setShowSignUpForm(false);
        setSelectedPackageOrBundle(null);
    };

    const toggleView = (categoryId) => {
        setCategoryViewStates(prev => {
            const providerCategory = selectedProvider.provider_categories
                .find(cat => cat.prov_cat_id === categoryId);
                
            if (!providerCategory) return prev;

            const newViewIsBundles = !prev[categoryId];
            const items = newViewIsBundles ? providerCategory.bundles : providerCategory.packages;
            
            // Check if we have items in the target view
            if (!items || items.length === 0) return prev;

            // Update indices first, but wait for state to be updated
            setTimeout(() => {
                setCurrentIndices(prevIndices => {
                    const defaultIndex = newViewIsBundles
                        ? items.findIndex(b => b.bundle_default === true)
                        : items.findIndex(p => p.package_default === true);

                    return {
                        ...prevIndices,
                        [categoryId]: defaultIndex >= 0 ? defaultIndex : 0
                    };
                });
            }, 0);

            // Return new view state
            return {
                ...prev,
                [categoryId]: newViewIsBundles
            };
        });
    };

    const handleNextCard = (categoryIndex) => {
        const providerCategory = selectedProvider.provider_categories[categoryIndex];
        if (!providerCategory) return;

        const currentArray = categoryViewStates[providerCategory.prov_cat_id]
            ? providerCategory.bundles || []
            : providerCategory.packages || [];

        if (currentArray.length > 0) {
            const currentIndex = currentIndices[providerCategory.prov_cat_id] || 0;
            const nextIndex = (currentIndex + 1) % currentArray.length;
            setCurrentIndices(prev => ({
                ...prev,
                [providerCategory.prov_cat_id]: nextIndex
            }));
        }
    };

    const handlePrevCard = (categoryIndex) => {
        const providerCategory = selectedProvider.provider_categories[categoryIndex];
        if (!providerCategory) return;

        const currentArray = categoryViewStates[providerCategory.prov_cat_id]
            ? providerCategory.bundles || []
            : providerCategory.packages || [];

        if (currentArray.length > 0) {
            const currentIndex = currentIndices[providerCategory.prov_cat_id] || 0;
            const prevIndex = (currentIndex - 1 + currentArray.length) % currentArray.length;
            setCurrentIndices(prev => ({
                ...prev,
                [providerCategory.prov_cat_id]: prevIndex
            }));
        }
    };

    const togglePromotions = () => {
        setShowPromotions(!showPromotions);
    };

    const handlePromotionPrevCard = (categoryId) => {
        const promoCategory = getPromotionalItemsByCategory()
            .find(cat => cat.promotion_category_id === categoryId);
            
        if (promoCategory) {
            const items = promotionViewStates[categoryId] 
                ? promoCategory.bundles 
                : promoCategory.packages;
                
            setPromotionIndices(prev => ({
                ...prev,
                [categoryId]: (prev[categoryId] - 1 + items.length) % items.length
            }));
        }
    };

    const handlePromotionNextCard = (categoryId) => {
        const promoCategory = getPromotionalItemsByCategory()
            .find(cat => cat.promotion_category_id === categoryId);
            
        if (promoCategory) {
            const items = promotionViewStates[categoryId] 
                ? promoCategory.bundles 
                : promoCategory.packages;
                
            setPromotionIndices(prev => ({
                ...prev,
                [categoryId]: (prev[categoryId] + 1) % items.length
            }));
        }
    };

    const togglePromotionView = (categoryId) => {
        setPromotionViewStates(prev => {
            const newState = {
                ...prev,
                [categoryId]: !prev[categoryId]
            };
            
            // Reset the index when switching views
            const promoCategory = getPromotionalItemsByCategory()
                .find(cat => cat.promotion_category_id === categoryId);
                
            if (promoCategory) {
                const newViewIsBundles = !prev[categoryId];
                const items = newViewIsBundles ? promoCategory.bundles : promoCategory.packages;
                
                // Find default item in the new view
                const defaultIndex = newViewIsBundles 
                    ? promoCategory.bundles.findIndex(b => b.bundle_default === true || b.promo_bundle_default === true)
                    : promoCategory.packages.findIndex(p => p.package_default === true || p.promo_package_default === true);
                
                setPromotionIndices(prevIndices => ({
                    ...prevIndices,
                    [categoryId]: defaultIndex >= 0 ? defaultIndex : 0 // Use default if exists, otherwise use first item
                }));
            }
            
            return newState;
        });
    };

    // First, let's organize promotions by category
    const getPromotionalItemsByCategory = () => {
        const promotionalItems = [];
        const allPromotionCategories = new Set();
        
        // First, collect all unique promotion categories across all providers
        data.technology_classes.forEach(tc => {
            tc.providers.forEach(provider => {
                provider.provider_categories.forEach(category => {
                    category.promotion_categories.forEach(promoCategory => {
                        allPromotionCategories.add(JSON.stringify(promoCategory));
                    });
                });
            });
        });

        // Convert back to array and sort by priority
        const sortedPromotionCategories = Array.from(allPromotionCategories)
            .map(pc => JSON.parse(pc))
            .sort((a, b) => a.promotion_category_priority - b.promotion_category_priority);

        // For each promotion category, collect all matching packages and bundles
        sortedPromotionCategories.forEach(promoCategory => {
            const packages = [];
            const bundles = [];

            data.technology_classes.forEach(tc => {
                tc.providers.forEach(provider => {
                    provider.provider_categories.forEach(category => {
                        // Add provider and category info to packages and bundles
                        const categoryPackages = category.promotional_packages
                            .filter(pkg => pkg.promotion_category_id === promoCategory.promotion_category_id)
                            .map(pkg => ({
                                ...pkg,
                                provider_name: provider.provider_name,
                                provider_image_url: provider.provider_image_url,
                                prov_cat_name: category.prov_cat_name,
                                prov_cat_image_url: category.prov_cat_image_url
                            }));
                        
                        const categoryBundles = category.promotional_bundles
                            .filter(bundle => bundle.promotion_category_id === promoCategory.promotion_category_id)
                            .map(bundle => ({
                                ...bundle,
                                provider_name: provider.provider_name,
                                provider_image_url: provider.provider_image_url,
                                prov_cat_name: category.prov_cat_name,
                                prov_cat_image_url: category.prov_cat_image_url
                            }));

                        packages.push(...categoryPackages);
                        bundles.push(...categoryBundles);
                    });
                });
            });

            if (packages.length > 0 || bundles.length > 0) {
                promotionalItems.push({
                    ...promoCategory,
                    packages,
                    bundles
                });
            }
        });

        return promotionalItems;
    };

    const hasPromotions = () => {
        if (!data) return false;
        
        return data.technology_classes.some(tc => 
            tc.providers.some(provider => 
                provider.provider_categories.some(category => 
                    (category.promotional_packages && category.promotional_packages.length > 0) || 
                    (category.promotional_bundles && category.promotional_bundles.length > 0)
                )
            )
        );
    };

    const handleImageClick = (imageUrl, altText) => {
        setSelectedImage({ url: imageUrl, alt: altText });
        setShowImageModal(true);
    };

    const findMatchingPromotion = (packageId, providerCategory, isBundle = false) => {
        if (!providerCategory) return null;
        
        if (isBundle) {
            // Only check promotional bundles when in bundle view
            const matchingBundle = providerCategory.promotional_bundles?.find(
                promo => promo.package_id === packageId
            );
            if (matchingBundle) return { item: matchingBundle, type: 'bundle' };
        } else {
            // Only check promotional packages when in package view
            const matchingPackage = providerCategory.promotional_packages?.find(
                promo => promo.package_id === packageId
            );
            if (matchingPackage) return { item: matchingPackage, type: 'package' };
        }
        
        return null;
    };

    return (
        <div className="container mt-4">
            <div className="address-section">
                <h1 className="text-center">Service Coverage For:</h1>
                <h3 className="text-center"> {data && data.coverageCheck.address}</h3>
                {notFoundMessage && (
                    <div className="alert alert-warning text-center" role="alert" dangerouslySetInnerHTML={{ __html: notFoundMessage }} />
                )}
                {noServicesMessage && (
                    <div className="alert alert-info text-center" role="alert">
                        {noServicesMessage}
                    </div>
                )}
            </div>

            

            {data && data.technology_classes.length > 0 && (
                <>
                
                <div className="section-header noselect">
                        <h2 className="text-center">Step 1 - Choose Use Case</h2>
                        </div>
                    <div className="tech-class-section noselect">
                        
                        <div className="d-flex justify-content-center align-items-center">
                            <button 
                                className="btn btn-trusc-carousel" 
                                onClick={handlePrevTechClass}
                                disabled={techClassIndex === 0}
                            >❮</button>
                            {data && data.technology_classes[techClassIndex] && (
                                <div className="text-center mx-4 noselect">
                                    <img src={process.env.REACT_APP_IMAGE_URL + '/' + data.technology_classes[techClassIndex].image_url} alt={data.technology_classes[techClassIndex].tech_class_name} style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                                    <div><h3>{data.technology_classes[techClassIndex].tech_class_name}</h3></div>
                                </div>
                            )}
                            <button 
                                className="btn btn-trusc-carousel" 
                                onClick={handleNextTechClass}
                                disabled={techClassIndex === (data?.technology_classes?.length || 1) - 1}
                            >❯</button>
                        </div>
                    </div>

                    {selectedTechClass && (

                        <div className="provider-section noselect" ref={providerRef}>
                            <div className="section-header">
                                <h2 className="text-center">Step 2 - Choose Your {selectedTechClass.tech_class_name} Provider</h2>
                            </div>
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '150px' }}>
                                <button 
                                    className="btn btn-trusc-carousel" 
                                    onClick={handlePrevProvider}
                                    disabled={providerIndex === 0}
                                >❮</button>
                                {selectedTechClass.providers[providerIndex] && (
                                    <div className="text-center mx-4">
                                        <img 
                                            src={process.env.REACT_APP_IMAGE_URL + '/' + selectedTechClass.providers[providerIndex].provider_image_url} 
                                            alt={selectedTechClass.providers[providerIndex].provider_name} 
                                            style={{ 
                                                width: '150px', 
                                                height: '150px', 
                                                objectFit: 'contain',
                                                display: 'block',
                                                margin: 'auto'
                                            }} 
                                        />
                                        
                                    </div>
                                    
                                )}
                                <button 
                                    className="btn btn-trusc-carousel" 
                                    onClick={handleNextProvider}
                                    disabled={providerIndex === (selectedTechClass?.providers?.length || 1) - 1}
                                >❯</button>
                            </div>
                        </div>
                    )}

                    {selectedProvider && (
                        <div className="bundle-package-section noselect" ref={packagesRef}>
                            {selectedProvider.provider_categories.map((providerCategory, categoryIndex) => (
                                <div key={providerCategory.prov_cat_id} className="provider-category-section mb-5">
                                    <div className="section-header">
                                        <h2 className="text-center">
                                            <span className="category-heading"> {providerCategory.prov_cat_name} </span>
                                            | {categoryViewStates[providerCategory.prov_cat_id] ? 'Bundles' : 'Packages'}
                                        </h2>
                                    </div>
                                    <div className="category-description text-center mb-3">
                <p>{providerCategory.prov_cat_description || ''}</p>
            </div>
                                    
                                    {providerCategory.bundles?.length > 0 && providerCategory.packages?.length > 0 && (
                                        <>
                                            <div className="d-flex justify-content-center mb-2">
                                                <button 
                                                    className={`btn ${categoryViewStates[providerCategory.prov_cat_id] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`} 
                                                    onClick={() => toggleView(providerCategory.prov_cat_id)}
                                                >
                                                    Bundles
                                                </button>
                                                <button 
                                                    className={`btn ${!categoryViewStates[providerCategory.prov_cat_id] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`} 
                                                    onClick={() => toggleView(providerCategory.prov_cat_id)}
                                                >
                                                    Packages
                                                </button>
                                            </div>
                                            <p className="text-center mb-3">
                                                Currently showing: {categoryViewStates[providerCategory.prov_cat_id] ? 'Bundles' : 'Packages'}
                                            </p>
                                        </>
                                    )}

                                    <p className="text-center description-text">
                                        {categoryViewStates[providerCategory.prov_cat_id]
                                            ? ""
                                            : ""}
                                    </p>

                                    <div className="package-cards-container">
                                        <button 
                                            className="btn btn-trusc-carousel" 
                                            onClick={(e) => {
                                                if (!e.currentTarget.disabled) {
                                                    handlePrevCard(categoryIndex);
                                                }
                                            }}
                                            disabled={currentIndices[providerCategory.prov_cat_id] === 0}
                                        >❮</button>
                                        <div className="package-cards noselect">
                                            {categoryViewStates[providerCategory.prov_cat_id] ? (
                                                providerCategory.bundles?.length > 0 && currentIndices[providerCategory.prov_cat_id] !== undefined && 
                                                providerCategory.bundles[currentIndices[providerCategory.prov_cat_id]] ? (
                                                    <div className="package-card">
                                                        {(() => {
                                                            const currentBundle = providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0];
                                                            if (!currentBundle) return null;
                                                            const matchingPromo = findMatchingPromotion(currentBundle?.package_id, providerCategory, true);
                                                            const currentItem = currentBundle;

                                                            return (
                                                                <>
                                                                    {currentBundle && (
                                                                        <img 
                                                                            src={`${process.env.REACT_APP_IMAGE_URL}/${providerCategory.prov_cat_image_url}`}
                                                                            alt={currentBundle.bundle_name || currentBundle.package_name} 
                                                                            className="card-img-top" 
                                                                        />
                                                                    )}
                                                                    <div className="card-body">
                                                                        {matchingPromo && (
                                                                            <div className="promotion-badge">
                                                                                Promotional Offer!
                                                                            </div>
                                                                        )}
                                                                        <h5 className="card-title">
                                                                            {currentBundle.bundle_name || currentBundle.package_name}
                                                                        </h5>
                                                                        <div className="card-speeds">
                                                                            <p>
                                                                                <i className="fa-solid fa-cloud-arrow-down"></i> 
                                                                                {currentBundle.download_speed_mb} Mbps
                                                                            </p>
                                                                            <p>
                                                                                <i className="fa-solid fa-cloud-arrow-up"></i> 
                                                                                {currentBundle.upload_speed_mb} Mbps
                                                                            </p>
                                                                        </div>
                                                                        <div className="card-costs">
                                                                            <p>
                                                                                <span className="cost-heading">Installation Cost: </span>
                                                                                <InstallationPrice 
                                                                                  basePrice={matchingPromo ? matchingPromo.item.base_installation_price : currentBundle?.base_installation_price}
                                                                                  promoPrice={matchingPromo ? matchingPromo.item.installation_price : currentBundle?.installation_price}
                                                                                />
                                                                            </p>
                                                                            <p className="card-text">
                                                                                <i className="fa-solid fa-calendar-alt"></i> Term: {categoryViewStates[providerCategory.prov_cat_id]
                                                                                    ? (providerCategory.bundles?.[currentIndices[providerCategory.prov_cat_id]]?.term || 0)
                                                                                    : (providerCategory.packages?.[currentIndices[providerCategory.prov_cat_id]]?.term || 0)} months
                                                                            </p>
                                                                        </div>
                                                                        {currentBundle.bundle_description && (
                                                                            <div className="card-text mb-3">
                                                                                <i className="fa-solid fa-info-circle"></i> {currentBundle.bundle_description}
                                                                            </div>
                                                                        )}
                                                                        
                                                                        <div className="bundle-products">
                                                                            <h6>Included Products:</h6>
                                                                            {matchingPromo ? (
                                                                                // Show promotional VAPs
                                                                                matchingPromo.item.ValueAddedProducts?.map((product) => (
                                                                                    <div key={product.vap_id} className="d-flex align-items-center justify-content-center mb-2">
                                                                                        <img 
                                                                                            src={`${process.env.REACT_APP_IMAGE_URL}/${product.vap_image_url}`}
                                                                                            alt={product.vap_name} 
                                                                                            className="mr-2 cursor-pointer" 
                                                                                            style={{
                                                                                                width: '50px', 
                                                                                                height: '50px', 
                                                                                                objectFit: 'contain',
                                                                                                cursor: 'pointer'
                                                                                            }} 
                                                                                            onClick={() => handleImageClick(
                                                                                                process.env.REACT_APP_IMAGE_URL + '/' + (product.vap_image_url || ''),
                                                                                                product.vap_name || ''
                                                                                            )}
                                                                                        />
                                                                                        <div>
                                                                                            <p className="mb-0">
                                                                                                {product.PromotionalBundleVAP?.quantity || 1}x {product.vap_name}
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                {parseFloat(product.PromotionalBundleVAP?.price || '0') === 0 ? (
                                                                                                     <span style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {parseFloat(product.PromotionalBundleVAP?.price || '0') < parseFloat(product.base_vap_price || '0') && (
                                                                                                            <span className="strike-through"><del>R{product.base_vap_price}</del> </span>
                                                                                                        )}
                                                                                                        <span className="promotional-price">R{product.PromotionalBundleVAP?.price} purchase</span>
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                // Show regular VAPs
                                                                                currentBundle.ValueAddedProducts?.map((product) => (
                                                                                    <div key={product.vap_id} className="d-flex align-items-center justify-content-center mb-2">
                                                                                        <img 
                                                                                            src={`${process.env.REACT_APP_IMAGE_URL}/${product.vap_image_url}`}
                                                                                            alt={product.vap_name} 
                                                                                            className="mr-2 cursor-pointer" 
                                                                                            style={{
                                                                                                width: '50px', 
                                                                                                height: '50px', 
                                                                                                objectFit: 'contain',
                                                                                                cursor: 'pointer'
                                                                                            }} 
                                                                                            onClick={() => handleImageClick(
                                                                                                process.env.REACT_APP_IMAGE_URL + '/' + (product.vap_image_url || ''),
                                                                                                product.vap_name || ''
                                                                                            )}
                                                                                        />
                                                                                        <div>
                                                                                            <p className="mb-0">
                                                                                                {product.BundleValueAddedProduct?.quantity || 0} x {product.vap_name}
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                {parseFloat(product.BundleValueAddedProduct?.price || '0') === 0 ? (
                                                                                                     <span style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {parseFloat(product.BundleValueAddedProduct?.price || '0') < parseFloat(product.vap_sell || '0') && (
                                                                                                            <span className="strike-through"><del>R{product.vap_sell}</del> </span>
                                                                                                        )}
                                                                                                        <span className="promotional-price">R{product.BundleValueAddedProduct?.price || 0} purchase</span>
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            )}
                                                                        </div>
                                                                        <div className="card-price">
                                                                            <h5>
                                                                                {(() => {
                                                                                    const matchingPromo = findMatchingPromotion(currentBundle?.package_id, providerCategory, true);
                                                                                    const currentItem = currentBundle;

                                                                                    if (matchingPromo) {
                                                                                        // Show promotional pricing
                                                                                        if (matchingPromo.type === 'bundle') {
                                                                                            return (
                                                                                                <>
                                                                                                    {parseFloat(matchingPromo.item.package_price) < parseFloat(matchingPromo.item.base_package_price) && (
                                                                                                        <span className="text-muted">
                                                                                                            <del>R{matchingPromo.item.base_package_price}</del>{' '}
                                                                                                        </span>
                                                                                                    )}
                                                                                                    R{matchingPromo.item.package_price}/pm
                                                                                                </>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <>
                                                                                                    {parseFloat(matchingPromo.item.sell_price_incl) < parseFloat(matchingPromo.item.base_package_price) && (
                                                                                                        <span className="text-muted">
                                                                                                            <del>R{matchingPromo.item.base_package_price}</del>{' '}
                                                                                                        </span>
                                                                                                    )}
                                                                                                    R{matchingPromo.item.sell_price_incl}/pm
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        // Show regular pricing
                                                                                        return (
                                                                                            <>
                                                                                                {parseFloat(currentItem?.package_price) < parseFloat(currentItem?.base_package_price) && (
                                                                                                    <span className="text-muted">
                                                                                                        <del>R{currentItem?.base_package_price}</del>{' '}
                                                                                                    </span>
                                                                                                )}
                                                                                                R{currentItem?.package_price}/pm
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                })()}
                                                                            </h5>
                                                                        </div>
                                                                        <button className="btn btn-trusc-primary" onClick={() => handleBundleSignUp(providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0])}>Sign Up</button>
                                                                    </div>
                                                                </>
                                                            );
                                                        })()}
                                                    </div>
                                                ) : (
                                                    <div>No bundles available for this provider category.</div>
                                                )
                                            ) : (
                                                providerCategory.packages?.length > 0 && currentIndices[providerCategory.prov_cat_id] !== undefined ? (
                                                    <div className="package-card">
                                                        {(() => {
                                                            const currentPackage = providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0];
                                                            const matchingPromo = findMatchingPromotion(currentPackage?.package_id, providerCategory, false);
                                                            const currentItem = currentPackage;
                                                            
                                                            return (
                                                                <>
                                                                    <img 
                                                                        src={`${process.env.REACT_APP_IMAGE_URL}/${providerCategory.prov_cat_image_url}`}
                                                                        alt={currentPackage.package_name} 
                                                                        className="card-img-top" 
                                                                    />
                                                                    <div className="card-body">
                                                                        {matchingPromo && (
                                                                            <div className="promotion-badge">
                                                                                Promotional Offer!
                                                                            </div>
                                                                        )}
                                                                        <h5 className="card-title">
                                                                            {currentPackage.package_name}
                                                                        </h5>
                                                                        <div className="card-speeds">
                                                                            <p>
                                                                                <i className="fa-solid fa-cloud-arrow-down"></i> 
                                                                                {currentPackage.download_speed_mb} Mbps
                                                                            </p>
                                                                            <p>
                                                                                <i className="fa-solid fa-cloud-arrow-up"></i> 
                                                                                {currentPackage.upload_speed_mb} Mbps
                                                                            </p>
                                                                        </div>
                                                                        <div className="card-costs">
                                                                            <p>
                                                                                <span className="cost-heading">Installation Cost: </span>
                                                                                <InstallationPrice 
                                                                                  basePrice={matchingPromo ? matchingPromo.item.base_installation_price : currentPackage?.installation}
                                                                                  
                                                                                  promoPrice={matchingPromo ? matchingPromo.item.installation : currentPackage?.installation}
                                                                                />
                                                                            </p>
                                                                            <p className="card-text">
                                                                                <i className="fa-solid fa-calendar-alt"></i> Term: {providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].term} months
                                                                            </p>
                                                                        </div>
                                                                        {currentPackage.package_description && (
                                                                            <div className="card-text mb-3">
                                                                                <i className="fa-solid fa-info-circle"></i> {currentPackage.package_description}
                                                                            </div>
                                                                        )}

                                                                        <div className="card-price">
                                                                            <h5>
                                                                                {(() => {
                                                                                    const matchingPromo = findMatchingPromotion(currentPackage?.package_id, providerCategory, false);
                                                                                    const currentItem = currentPackage;

                                                                                    if (matchingPromo) {
                                                                                        // Show promotional pricing
                                                                                        return (
                                                                                            <>
                                                                                                {parseFloat(matchingPromo.item.sell_price_incl) < parseFloat(matchingPromo.item.base_package_price) && (
                                                                                                    <span className="text-muted">
                                                                                                        <del>R{matchingPromo.item.base_package_price}</del>{' '}
                                                                                                    </span>
                                                                                                )}
                                                                                                R{matchingPromo.item.sell_price_incl}/pm
                                                                                            </>
                                                                                        );
                                                                                    } else {
                                                                                        // Show regular pricing
                                                                                        return (
                                                                                            <>
                                                                                                {parseFloat(currentItem?.sell_price_incl) < parseFloat(currentItem?.base_package_price) && (
                                                                                                    <span className="text-muted">
                                                                                                        <del>R{currentItem?.base_package_price}</del>{' '}
                                                                                                    </span>
                                                                                                )}
                                                                                                R{currentItem?.sell_price_incl}/pm
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                })()}
                                                                            </h5>
                                                                        </div>
                                                                        <button className="btn btn-trusc-primary" onClick={() => handleSignUp(providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0])}>Sign Up</button>
                                                                    </div>
                                                                </>
                                                            );
                                                        })()}
                                                    </div>
                                                ) : (
                                                    <div>No packages available for this provider category.</div>
                                                )
                                            )}
                                        </div>
                                        <button 
                                            className="btn btn-trusc-carousel" 
                                            onClick={(e) => {
                                                if (!e.currentTarget.disabled) {
                                                    handleNextCard(categoryIndex);
                                                }
                                            }}
                                            disabled={currentIndices[providerCategory.prov_cat_id] === 
                                                (categoryViewStates[providerCategory.prov_cat_id] 
                                                    ? (providerCategory.bundles?.length || 1) - 1
                                                    : (providerCategory.packages?.length || 1) - 1)
                                            }
                                        >❯</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {showSignUpForm && (
                        <SignUpForm
                            show={showSignUpForm}
                            handleClose={() => setShowSignUpForm(false)}
                            selectedPackage={selectedPackageOrBundle}
                            selectedProviderCategory={selectedProvider.provider_categories[0]}
                            address={data.coverageCheck.address}
                            latitude={latitude}
                            longitude={longitude}
                            tech_class_user_type={selectedTechClass.tech_class_user_type}
                            coverageCheck={data.coverageCheck}
                        />
                    )}
                </>
            )}
            {showImageModal && (
                <ImageModal
                    show={showImageModal}
                    handleClose={() => setShowImageModal(false)}
                    imageUrl={selectedImage.url}
                    altText={selectedImage.alt}
                />
            )}
        </div>
    );
};

export default PackagesDisplay;
