import React, { useEffect, useState } from 'react';
import News from '../components/News';
import TeamMemberInfo from '../components/TeamMemberInfo';
import './HomePage.css';
import CoverageMap from '../components/CoverageMap';

const HomePage = () => {
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleMapsApi = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,marker`;
      script.async = true;
      script.defer = true;
      script.onload = () => setIsApiLoaded(true);
      document.body.appendChild(script);
    };

    loadGoogleMapsApi();
  }, []);

  return (
    <div className="home-page">
      
      <section className="news">
        <News />
      </section>

      <section className="coverage">
        {isApiLoaded ? <CoverageMap /> : <p></p>}
      </section>

      <TeamMemberInfo />
    </div>
  );
};

export default HomePage;
