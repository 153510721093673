import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import executeRecaptcha from '../utils/recaptcha';

const convertDMSToDD = (degrees, minutes, seconds, direction) => {
    let dd = degrees + minutes/60 + seconds/3600;
    if (direction === 'S' || direction === 'W') {
        dd = dd * -1;
    }
    return dd;
};

const parseCoordinates = (input) => {
    // Handle decimal coordinates (e.g., -32.953990, 19.048743)
    const decimalRegex = /^-?\d+\.?\d*,\s*-?\d+\.?\d*$/;
    if (decimalRegex.test(input)) {
        const [lat, lng] = input.split(',').map(coord => parseFloat(coord.trim()));
        return { lat, lng };
    }

    // Handle DMS coordinates (e.g., 32°57'14.4"S 19°02'55.5"E)
    const dmsRegex = /(\d+)°(\d+)'(\d+\.?\d*)"([NS])\s+(\d+)°(\d+)'(\d+\.?\d*)"([EW])/;
    const dmsMatch = input.match(dmsRegex);
    if (dmsMatch) {
        const [_, latDeg, latMin, latSec, latDir, lngDeg, lngMin, lngSec, lngDir] = dmsMatch;
        const lat = convertDMSToDD(
            parseFloat(latDeg),
            parseFloat(latMin),
            parseFloat(latSec),
            latDir
        );
        const lng = convertDMSToDD(
            parseFloat(lngDeg),
            parseFloat(lngMin),
            parseFloat(lngSec),
            lngDir
        );
        return { lat, lng };
    }

    return null;
};

const CoverageMap = () => {
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [address, setAddress] = useState('');
    const [results, setResults] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [promoDetails, setPromoDetails] = useState(null);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('package_id');
    const bundleId = urlParams.get('bundle_id');
    const promoPackageId = urlParams.get('promo_package_id');
    const promoBundleId = urlParams.get('promo_bundle_id');

    const selectedId = packageId || bundleId || promoPackageId || promoBundleId;

    useEffect(() => {
        const fetchPromoDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-display/promotions`);
                
                if (promoPackageId && response.data.promotionalPackages) {
                    const foundPackage = response.data.promotionalPackages.find(
                        p => p.promotional_package_id === parseInt(promoPackageId)
                    );
                    if (foundPackage) {
                        setPromoDetails({
                            type: 'Package',
                            name: foundPackage.package_name || foundPackage.Package?.package_name,
                            providerName: foundPackage.Package?.ProviderCategory?.prov_cat_name || 'N/A'
                        });
                    }
                } else if (promoBundleId && response.data.promotionalBundles) {
                    const foundBundle = response.data.promotionalBundles.find(
                        b => b.bundle_id === parseInt(promoBundleId)
                    );
                    if (foundBundle) {
                        setPromoDetails({
                            type: 'Bundle',
                            name: foundBundle.bundle_name,
                            providerName: foundBundle.ProviderCategory?.prov_cat_name || 'N/A'
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching promo details:', error);
            }
        };

        if (promoPackageId || promoBundleId) {
            fetchPromoDetails();
        }
    }, [promoPackageId, promoBundleId]);

    useEffect(() => {
        const initMap = () => {
            const initialLocation = { lat: -26.2041, lng: 28.0473 }; // Johannesburg
            const googleMap = new window.google.maps.Map(document.getElementById("map"), {
                zoom: 15,
                center: initialLocation,
            });

            const googleMarker = new window.google.maps.Marker({
                position: initialLocation,
                map: googleMap,
                draggable: true,
            });

            const geocoder = new window.google.maps.Geocoder();

            googleMarker.addListener("dragend", (event) => {
                const latLng = event.latLng;
                geocoder.geocode({ location: latLng }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        setAddress(results[0].formatted_address);
                    } else {
                        setAddress(`${latLng.lat()}, ${latLng.lng()}`);
                    }
                });
            });

            setMap(googleMap);
            setMarker(googleMarker);

            // Try to get user's location
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        const latLng = new window.google.maps.LatLng(latitude, longitude);
                        googleMap.setCenter(latLng);
                        googleMarker.setPosition(latLng);
                        
                        geocoder.geocode({ location: latLng }, (results, status) => {
                            if (status === "OK" && results[0]) {
                                setAddress(results[0].formatted_address);
                            } else {
                                setAddress(`${latitude}, ${longitude}`);
                            }
                        });
                    },
                    (error) => {
                        console.error("Error getting user location:", error);
                    }
                );
            }
        };

        initMap();
    }, []);

    useEffect(() => {
        if (map && marker) { // Ensure marker is defined
            const input = document.getElementById("address");
            const autocomplete = new window.google.maps.places.Autocomplete(input);
            autocomplete.bindTo("bounds", map);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    map.setCenter(place.geometry.location);
                    marker.setPosition(place.geometry.location);
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [map, marker]); // Add marker to the dependency array

    useEffect(() => {
        if (map && marker) {
            const coordinates = parseCoordinates(address);
            if (coordinates) {
                const latLng = new window.google.maps.LatLng(coordinates.lat, coordinates.lng);
                map.setCenter(latLng);
                marker.setPosition(latLng);
            }
        }
    }, [address, map, marker]);

    const checkCoverage = async () => {
        setIsLoading(true);
        
        // Execute reCAPTCHA verification
        const isVerified = await executeRecaptcha('coverage_check');
        if (!isVerified) {
            setIsLoading(false);
            alert('Verification failed. Please try again.');
            return;
        }

        try {
            let coordinates;
            const parsedCoords = parseCoordinates(address);
            
            if (parsedCoords) {
                // Use parsed coordinates
                coordinates = {
                    latitude: parsedCoords.lat,
                    longitude: parsedCoords.lng
                };
            } else {
                // Use marker position if no valid coordinates in address
                const position = marker.getPosition();
                coordinates = {
                    latitude: position.lat(),
                    longitude: position.lng()
                };
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/coverage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(coordinates),
            });

            const resultsData = await response.json();
            //console.log(resultsData); // Log the resultsData to the console
            handleCoverageResponse(resultsData);
        } catch (error) {
            console.error('Error during coverage check:', error);
            alert('An error occurred during the coverage check. Please try again.');
        }
        
        setIsLoading(false);
    };

    // Add this helper function to handle the coverage response
    const handleCoverageResponse = (resultsData) => {
        const urlParams = new URLSearchParams(window.location.search);
        const techClassId = urlParams.get('tech_class_id');

        if (resultsData.error || (resultsData && resultsData.technology_classes)) {
            navigate('/packages-display', { 
                state: { 
                    coverageData: resultsData,
                    selectedId: selectedId,
                    selectedType: packageId ? 'package' : bundleId ? 'bundle' : promoPackageId ? 'promo_package' : 'promo_bundle',
                    techClassId: techClassId
                } 
            });
        } else {
            alert('Invalid response format. Please try again.');
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            setIsLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const latLng = new window.google.maps.LatLng(latitude, longitude);
                    map.setCenter(latLng);
                    marker.setPosition(latLng);

                    // Use Geocoding API to get the address
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            setAddress(results[0].formatted_address);
                        } else {
                            setAddress(`${latitude}, ${longitude}`);
                        }
                        setIsLoading(false);
                    });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    alert("Unable to retrieve your location. Please enter an address manually.");
                    setIsLoading(false);
                }
            );
        } else {
            alert("Geolocation is not supported by your browser. Please enter an address manually.");
        }
    };

    return (
        <div className="container mt-1 p-4" style={{ backgroundColor: 'white', borderRadius: '0px', marginTop: '0px', maxWidth: '1280px' }}>
            {promoDetails && (
                <div className="alert alert-info text-center mb-4">
                    <h4 className="mb-0">
                        Check Service Availability For {promoDetails.providerName} {promoDetails.name} Promotional {promoDetails.type}
                    </h4>
                </div>
            )}
            <h1 className="text-center mb-4">Coverage Check</h1>
            <h5 className="text-center mb-4">Please enter your current address or gps coordinates / use current location button / drag the marker on the map to where you want service.</h5>
            <div className="input-group mb-3">
            <button 
                    className="btn btn-primary" 
                    onClick={getUserLocation}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Getting Location...
                        </>
                    ) : (
                        'Use Current Location'
                    )}
                </button>
                <input
                    id="address"
                    type="text"
                    className="form-control"
                    placeholder="Enter an address or coordinates (lat, lon)"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
               
                
            </div>
            <div className="d-flex justify-content-center">
            <button 
                id="checkCoverage" 
                className="btn btn-danger btn-block text-center mb-3" 
                onClick={checkCoverage} 
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Checking...
                    </>
                ) : (
                    'Check Coverage'
                )}
                </button>
            </div>
            <div id="map" className="mb-3" style={{ height: '500px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}></div>
    

        </div>
    );
};

export default CoverageMap;
