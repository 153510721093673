import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';

const SignUpSubmittedPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ticketNumber } = location.state || {};

  return (
    <Container className="mt-5">
      <Card className="text-center">
        <Card.Body>
          <Card.Title as="h2" className="mb-4">Thank You for Signing Up!</Card.Title>
          <Card.Text as="h4" className="mb-4">Your order has been submitted successfully.</Card.Text>
          <Card.Text className="mb-4">Please view your order details below to initiate payment</Card.Text>
        
        
          <Button 
            variant="primary" 
            className="mb-4"
            onClick={() => window.open(`/order-details/${ticketNumber}`, '_blank')}
          >
            ORDER DETAILS
          </Button>
          <Card.Text className="lead mb-4 mt-4">
            Your order number is: <strong>{ticketNumber}</strong>  
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUpSubmittedPage;
