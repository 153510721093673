// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .hero-about {
    background-color: #003CA6 !important;
  }

  .our-story {
    background-color: white;
    color: #000000;
    
    
  }

  .our-vision {
    background-color: #003CA6!important;
    color: white;
    
  }

  .our-impact {
    background-color: white;
    color: #000000;
   
  }

  .our-services {
    background-color: #003CA6!important;
    color: white;
  
  }

  .content-container {
    max-width: 1250px;
    margin: 0 auto;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/AboutPage.css"],"names":[],"mappings":"CAAC;IACG,oCAAoC;EACtC;;EAEA;IACE,uBAAuB;IACvB,cAAc;;;EAGhB;;EAEA;IACE,mCAAmC;IACnC,YAAY;;EAEd;;EAEA;IACE,uBAAuB;IACvB,cAAc;;EAEhB;;EAEA;IACE,mCAAmC;IACnC,YAAY;;EAEd;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[" .hero-about {\r\n    background-color: #003CA6 !important;\r\n  }\r\n\r\n  .our-story {\r\n    background-color: white;\r\n    color: #000000;\r\n    \r\n    \r\n  }\r\n\r\n  .our-vision {\r\n    background-color: #003CA6!important;\r\n    color: white;\r\n    \r\n  }\r\n\r\n  .our-impact {\r\n    background-color: white;\r\n    color: #000000;\r\n   \r\n  }\r\n\r\n  .our-services {\r\n    background-color: #003CA6!important;\r\n    color: white;\r\n  \r\n  }\r\n\r\n  .content-container {\r\n    max-width: 1250px;\r\n    margin: 0 auto;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
