import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactPage.css';
import executeRecaptcha from '../utils/recaptcha';

const ContactPage = () => {
  const [activeTab, setActiveTab] = useState('');
  const [branches, setBranches] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_WS_MANAGEMENT_URL}/branches`);
        // Filter active branches and sort by priority
        const activeBranches = response.data
          .filter(branch => branch.branch_status === 'active')
          .sort((a, b) => a.branch_priority - b.branch_priority);
        setBranches(activeBranches);
        if (activeBranches.length > 0) {
          setActiveTab(activeBranches[0].branch_name);
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Execute reCAPTCHA verification
    const isVerified = await executeRecaptcha('contact_form');
    if (!isVerified) {
      alert('Verification failed. Please try again.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/contact-submit`, formData);
      if (response.data.success) {
        alert('Message sent successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <div className="contact-page">
     <div className="container text-center">
          <h2 className="banner-title">Contact Us</h2>  
        </div>
      <div className="contact-content">
        <div className="contact-form">
        <div className="call-us">
            <h3><i className="fas fa-phone"></i> Call us</h3>
            <p>Questions about plans, pricing, or availability? Just have your people call our people.</p>
            <p className="phone-number">087 020 0000</p>
          </div>
         
          <h3><i className="fas fa-envelope"></i> Send us a mail</h3>
          <p>Our entire team receives training regularly to ensure you're receiving the best information possible. Please contact us for basic questions or technical support inquiries, we're here to help!</p>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input 
                type="text" 
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First name*" 
                required 
              />
              <input 
                type="text" 
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last name" 
              />
            </div>
            <div className="form-row">
            <input 
              type="email" 
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your email address*" 
              required 
            />
            <input 
              type="tel" 
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Your phone number*" 
              required
            />
            </div>
            <textarea 
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="How can we help you?*" 
              required
            ></textarea>
            <button type="submit" className="submit-btn">Submit</button>
          </form>
        </div>
      
      </div>
      <div className="our-branches">
        <div className="banner-title">
          <h2>Our Branches</h2>
        </div>
        <div className="branches-tabs">
          {branches.map(branch => (
            <button
              key={branch.branch_id}
              className={`tab ${activeTab === branch.branch_name ? 'active' : ''}`}
              onClick={() => setActiveTab(branch.branch_name)}
            >
              {branch.branch_name}
            </button>
          ))}
        </div>
        <div className="branch-details">
          {branches.map(branch => (
            branch.branch_name === activeTab && (
              <div key={branch.branch_id} className="branch-info-container">
                <div className="branch-info">
                  <h3>{`Trusc ${branch.branch_name}`}</h3>
                  <p><i className="fas fa-phone"></i> {branch.branch_contact_number}</p>
                  <p><i className="fas fa-envelope"></i> {branch.branch_email}</p>
                  <p><i className="fas fa-map-marker-alt"></i> {branch.branch_address}</p>
                  <p><i className="fas fa-clock"></i> {branch.branch_hours}</p>
                </div>
                <div className="branch-map">
                  <iframe
                    src={branch.branch_google_map_link}
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
