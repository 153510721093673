import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PackagesDisplay.css';
import SignUpForm from './SignUpForm.js';
import ImageModal from './ImageModal';

const InstallationPrice = ({ basePrice, promoPrice }) => {
  // Handle case where promoPrice is undefined
  if (!promoPrice) return null;

  // Handle free installation with possible base price
  if (parseFloat(promoPrice) === 0) {
    if (basePrice && parseFloat(basePrice) > 0) {
      return (
        <span className="installation-price">
          <span className="strike-through">R{basePrice}</span>
          <span style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>
          <span className="cost-post-text"></span>
        </span>
      );
    }
    return <span className="installation-price" style={{ color: 'red', fontWeight: 'bold' }}>Free!</span>;
  }

  // Show strike-through only if basePrice exists and is different from promoPrice
  if (basePrice && basePrice !== promoPrice) {
    return (
      <span className="installation-price">
        <span className="strike-through">R{basePrice}</span>
        <span className="promotional-price">R{promoPrice}</span>
        <span className="cost-post-text"></span>
      </span>
    );
  }

  // Default case - just show the promoPrice
  return (
    <span className="installation-price">
      R{promoPrice}<span className="cost-post-text"></span>
    </span>
  );
};

const MonthlyPrice = ({ basePrice, promoPrice }) => {
  const showStrikeThrough = basePrice && promoPrice && parseFloat(basePrice) > parseFloat(promoPrice);
  
  return (
    <div className="monthly-price">
      {showStrikeThrough ? (
        <>
          <span className="strike-through">R{parseFloat(basePrice).toFixed(2)}</span>
          <span className="promotional-price">R{parseFloat(promoPrice).toFixed(2)}/pm</span>
        </>
      ) : (
        <span>R{parseFloat(promoPrice).toFixed(2)}/pm</span>
      )}
    </div>
  );
};

const PackagesDisplay = () => {
    const location = useLocation();
    const [coverageData] = useState(location.state?.coverageData || null);

    const [data, setData] = useState(null);
    const [selectedServiceType, setSelectedServiceType] = useState(null);
    const [selectedTechClass, setSelectedTechClass] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedProviderCategory, setSelectedProviderCategory] = useState(null);
    const [showPackages, setShowPackages] = useState(true);
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [address, setAddress] = useState('');
    const [noServicesMessage, setNoServicesMessage] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', alt: '' });

    // Step tracking
    const [currentStep, setCurrentStep] = useState(1);

    // References for scrolling
    const serviceTypeRef = useRef(null);
    const providerCategoryRef = useRef(null);
    const packagesRef = useRef(null);

    // New derived state to get all unique tech classes across all service types
    const [allTechClasses, setAllTechClasses] = useState([]);

    useEffect(() => {
        if (coverageData) {
            console.log("Coverage data received:", coverageData);
            console.log("Number of service types:", coverageData.service_types?.length);
            
            // Log out all service types to verify they're in the data
            if (coverageData.service_types) {
                coverageData.service_types.forEach(st => {
                    console.log(`Service type: ${st.serviceType_name}, Status: ${st.serviceType_status}`);
                });
            }
            
            if (coverageData.error) {
                setNoServicesMessage("No services are currently available at your location.");
            } else {
                setData(coverageData);
                setAddress(coverageData.coverage_check.address);
                
                // Extract all unique tech classes across all service types
                if (coverageData.service_types && coverageData.service_types.length > 0) {
                    // Use a Map to deduplicate tech classes by ID
                    const techClassMap = new Map();
                    
                    coverageData.service_types.forEach(st => {
                        if (st.serviceType_status === 'active' && st.technology_classes) {
                            st.technology_classes.forEach(tc => {
                                if (tc.techClass_status === 'active') {
                                    // Check if we already have this tech class
                                    if (techClassMap.has(tc.techClass_id)) {
                                        // Add this service type to existing tech class
                                        const existingTechClass = techClassMap.get(tc.techClass_id);
                                        existingTechClass.serviceTypes.push({
                                            id: st.serviceType_id,
                                            name: st.serviceType_name,
                                            providers: tc.providers
                                        });
                                    } else {
                                        // Create new tech class with first service type
                                        const techClassCopy = {...tc};
                                        techClassCopy.serviceTypes = [{
                                            id: st.serviceType_id,
                                            name: st.serviceType_name,
                                            providers: tc.providers
                                        }];
                                        techClassMap.set(tc.techClass_id, techClassCopy);
                                    }
                                }
                            });
                        }
                    });
                    
                    // Convert map to array
                    const techClasses = Array.from(techClassMap.values());
                    
                    // Sort by priority
                    techClasses.sort((a, b) => a.techClass_priority - b.techClass_priority);
                    
                    console.log("Available use cases:", techClasses.map(tc => tc.techClass_name));
                    setAllTechClasses(techClasses);
                    
                    // Don't auto-select service type anymore
                    // Instead, wait for user to select a use case
                }
            }
        }
    }, [coverageData]);

    const handleTechClassSelect = (techClass) => {
        setSelectedTechClass(techClass);
        
        // When a tech class is selected, show the combined list of all providers from all service types
        setSelectedProvider(null);
        setSelectedProviderCategory(null);
        setCurrentStep(2);
        
        // Scroll to provider section
        if (providerCategoryRef.current) {
            providerCategoryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleProviderCategorySelect = (provider, providerCategory) => {
        setSelectedProvider(provider);
        setSelectedProviderCategory(providerCategory);
        setCurrentStep(3);
        
        // Scroll to packages section
        if (packagesRef.current) {
            packagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePackageSelect = (pkg, variant) => {
        setSelectedPackage(pkg);
        setSelectedVariant(variant);
        setShowSignUpForm(true);
    };

    const togglePackageView = () => {
        setShowPackages(!showPackages);
    };

    const openImageModal = (imageId, alt) => {
        // Use the environment variable for images
        const url = `${process.env.REACT_APP_IMAGE_URL}/${imageId}`;
        setSelectedImage({ url, alt });
        setShowImageModal(true);
    };

    const renderUseCaseSelection = () => {
        if (!allTechClasses.length) return null;
        
        return (
            <div className="use-case-selection" ref={serviceTypeRef}>
                <div className="step-header">
                    <h2>Step 1 - Select Use Case</h2>
                </div>
                <div className="tech-classes-container">
                    {allTechClasses.map(techClass => (
                        <div 
                            key={techClass.techClass_id} 
                            className={`tech-class-card ${selectedTechClass?.techClass_id === techClass.techClass_id ? 'selected' : ''}`}
                            onClick={() => handleTechClassSelect(techClass)}
                        >
                            <div className="tech-class-header">
                                {techClass.techClass_logoImage_id && (
                                    <img 
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${techClass.techClass_logoImage_id}`} 
                                        alt={techClass.techClass_name} 
                                        className="tech-class-logo"
                                    />
                                )}
                                <div className="tech-class-name">{techClass.techClass_name}</div>
                            </div>
                        
                            
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderStep2 = () => {
        if (!selectedTechClass) return null;
        
        // Flatten all providers from all service types for this tech class
        const allProviders = [];
        selectedTechClass.serviceTypes.forEach(serviceType => {
            if (serviceType.providers) {
                serviceType.providers.forEach(provider => {
                    // Annotate provider categories with their service type
                    const providerWithServiceType = {...provider};
                    providerWithServiceType.provider_categories = provider.provider_categories.map(pc => ({
                        ...pc,
                        serviceTypeInfo: {
                            id: serviceType.id,
                            name: serviceType.name
                        }
                    }));
                    allProviders.push(providerWithServiceType);
                });
            }
        });
        
        return (
            <div className="step-container" ref={providerCategoryRef}>
                <div className="step-header">
                    <h2>Step 2 - Choose Provider Category</h2>
                </div>
                <div className="provider-categories-container">
                    {allProviders.filter(p => p.provider_status === 'active').flatMap(provider => 
                        provider.provider_categories.filter(pc => pc.provCat_status === 'active').map(providerCategory => (
                            <div 
                                key={`${provider.provider_id}-${providerCategory.provCat_id}`} 
                                className={`provider-category-card ${selectedProviderCategory?.provCat_id === providerCategory.provCat_id ? 'selected' : ''}`}
                                onClick={() => handleProviderCategorySelect(provider, providerCategory)}
                            >
                                <div className="provider-category-image" onClick={() => openImageModal(providerCategory.provCat_image_id, providerCategory.provCat_name)}>
                                    <img 
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${providerCategory.provCat_image_id}`} 
                                        alt={providerCategory.provCat_name} 
                                        onError={(e) => {e.target.onerror = null; e.target.src = "https://via.placeholder.com/150"}}
                                    />
                                </div>
                                <div className="provider-category-name">{providerCategory.provCat_name}</div>
                                <div className="service-type-indicator">
                                    {providerCategory.serviceTypeInfo.name}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    };

    const renderStep3 = () => {
        if (!selectedProviderCategory) return null;
        
        return (
            <div className="step-container" ref={packagesRef}>
                <div className="step-header">
                    <h2>Showing {selectedProviderCategory.provCat_name}</h2>
                </div>
                
                <div className="provider-description">
                    <div className="provider-description-image">
                        <img 
                            src={`${process.env.REACT_APP_IMAGE_URL}/${selectedProviderCategory.provCat_image_id}`} 
                            alt={selectedProviderCategory.provCat_name} 
                            onError={(e) => {e.target.onerror = null; e.target.src = "https://via.placeholder.com/150"}}
                        />
                    </div>
                    <div className="provider-description-text">
                        {selectedProviderCategory.provCat_description}
                    </div>
                </div>
                
          
                
                
                
                <div className="packages-grid">
                    {selectedProviderCategory.packages && selectedProviderCategory.packages
                        .filter(pkg => pkg.package_status === 'active' && pkg.website_pkg === 1)
                        .sort((a, b) => a.package_priority - b.package_priority)
                        .map(pkg => {
                            // First check if there are variants available
                            const hasVariants = pkg.package_variants && pkg.package_variants.length > 0;
                            
                            // If no variants, create a default variant using package default values
                            const variants = hasVariants ? pkg.package_variants : [{
                                packageVariant_id: `default-${pkg.package_id}`,
                                package_id: pkg.package_id,
                                packageVariant_sell_price: pkg.package_default_sell_price,
                                packageVariant_sell_installation: pkg.package_default_sell_installation,
                                packageVariant_priority: 0,
                                packageVariant_type: "standard",
                                packageVariant_status: "active",
                                value_added_products: []
                            }];
                            
                            // Get the first/best variant to display
                            const firstVariant = variants[0];
                            
                            // Get promotion details if this is a promotional variant
                            const promotion = firstVariant.promotion_id ? 
                                data?.promotions?.find(p => p.promotion_id === firstVariant.promotion_id) : null;
                            
                            // Card style for promotional variants
                            const cardStyle = promotion ? {
                                borderColor: promotion.promotion_header_background_color,
                                borderWidth: '2px',
                                borderStyle: 'solid'
                            } : {};
                            
                            return (
                                <div 
                                    key={pkg.package_id} 
                                    className={`package-card ${promotion ? 'has-promotion' : ''}`}
                                    style={cardStyle}
                                >
                                    <div className="package-header">
                                        {pkg.package_name}
                                    </div>
                                    
                                    <div className="speed-details">
                                        <div className="speed-detail">
                                            <i className="fa fa-download"></i>
                                            <span>{pkg.download_speed_mb} Mbps</span>
                                        </div>
                                        <div className="speed-detail">
                                            <i className="fa fa-upload"></i>
                                            <span>{pkg.upload_speed_mb} Mbps</span>
                                        </div>
                                    </div>
                                    
                                    <div className="installation-details">
                                        <div>Installation Cost:</div>
                                        <InstallationPrice 
                                            basePrice={pkg.package_default_sell_installation} 
                                            promoPrice={firstVariant.packageVariant_sell_installation}
                                        />
                                    </div>
                                    
                                    <div className="term-details">
                                        <i className="fa fa-calendar"></i> Term: {pkg.term} months
                                    </div>
                                    
                                    <div className="package-description">
                                        {pkg.package_description}
                                    </div>
                                    
                                    <div className="included-products">
                                        <h4>Included Products:</h4>
                                        <ul>
                                            {firstVariant.value_added_products && firstVariant.value_added_products.map(vap => {
                                                return (
                                                    <li key={vap.vap_id} className="vap-item">
                                                        <div className="vap-image">
                                                            <img 
                                                                src={`${process.env.REACT_APP_IMAGE_URL}/${vap.vap_image_id}`} 
                                                                alt={vap.vap_name}
                                                                onError={(e) => {e.target.onerror = null; e.target.src = "https://via.placeholder.com/50"}}
                                                            />
                                                        </div>
                                                        <div className="vap-details">
                                                            <div className="vap-name">{vap.vap_quantity} x {vap.vap_name}</div>
                                                            <div className="vap-price">
                                                                {parseFloat(vap.vap_sell) > 0 ? `R${parseFloat(vap.vap_sell).toFixed(2)}` : 'Free with purchase'}
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    
                                    <div className="package-price">
                                        <MonthlyPrice 
                                            basePrice={pkg.package_default_sell_price} 
                                            promoPrice={firstVariant.packageVariant_sell_price}
                                        />
                                    </div>
                                    
                                    {/* Move promotion badge here, below the price */}
                                    {promotion && (
                                        <div 
                                            className="promotion-badge"
                                            style={{
                                                backgroundColor: promotion.promotion_header_background_color,
                                                color: promotion.promotion_header_text_color
                                            }}
                                        >
                                            {promotion.promotion_name}
                                        </div>
                                    )}
                                    
                                    <button 
                                        className="sign-up-button" 
                                        onClick={() => handlePackageSelect(pkg, firstVariant)}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    return (
        <div className="packages-display-container">
            <div className="header-section" style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <h1>Service Coverage For:</h1>
                <h2>{address}</h2>
                {noServicesMessage && <div className="no-services-message">{noServicesMessage}</div>}
            </div>
            
            {data && !noServicesMessage && (
                <>
                    {renderUseCaseSelection()}
                    
                    {selectedTechClass && renderStep2()}
                    
                    {currentStep >= 3 && renderStep3()}
                    
                    {showSignUpForm && selectedPackage && selectedVariant && (
                        <SignUpForm 
                            show={showSignUpForm}
                            handleClose={() => setShowSignUpForm(false)}
                            selectedPackage={{
                                ...selectedPackage,
                                ...selectedVariant,
                                packageVariant_type: selectedVariant.packageVariant_type || 'regular'
                            }}
                            selectedProviderCategory={selectedProviderCategory}
                            address={address}
                            latitude={coverageData.coverage_check.coordinates.lat}
                            longitude={coverageData.coverage_check.coordinates.lng}
                            techClass_name={selectedTechClass.techClass_name}
                            coverageCheck={coverageData.coverage_check}
                        />
                    )}
                    
                    {showImageModal && (
                        <ImageModal
                            imageUrl={selectedImage.url}
                            alt={selectedImage.alt}
                            onClose={() => setShowImageModal(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default PackagesDisplay;
