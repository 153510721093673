import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './News.css';

const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news-display`);
            setNews(response.data);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

    if (news.length === 0) {
        return null;
    }

    return (
        <div className="news-container">
            {news.map((item) => (
                <div 
                    key={item.news_id} 
                    className="news-item"
                >
                    <div 
                        className="news-title"
                        style={{
                            backgroundColor: item.news_title_background_color,
                            color: item.news_title_text_color,
                            padding: '0px',
                            marginBottom: '5px'
                        }}
                    >
                        {item.news_title}
                    </div>
                    <div className="news-content">
                        {item.news_content}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default News;    
