import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { checkTeamMemberSaleUrl } from '../utils/salesTracking';

const TeamMemberPage = () => {
    const { saleUrl } = useParams();

    useEffect(() => {
        checkTeamMemberSaleUrl(saleUrl);
    }, [saleUrl]);

    return <Navigate to="/" />;
};

export default TeamMemberPage;