import React, { useState } from 'react';
import testimonials from '../data/testimonials.json';
import './Testimonials.css';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">Our Customers Love us.</h2>
      <div className="testimonial-carousel">
        <button className="carousel-button left" onClick={prevTestimonial}>&lt;</button>
        <div className="testimonial-card">
          <div className="customer-opinion">CUSTOMER OPINION</div>
          <h3 className="testimonial-comment">{currentTestimonial.comment}</h3>
          <div className="testimonial-rating">
            {[...Array(currentTestimonial.rating)].map((_, i) => (
              <span key={i} className="star">★</span>
            ))}
          </div>
          <p className="testimonial-name">{currentTestimonial.name}</p>
          <p className="testimonial-role">{currentTestimonial.role}</p>
        </div>
        <button className="carousel-button right" onClick={nextTestimonial}>&gt;</button>
      </div>
    </div>
  );
};

export default Testimonials;