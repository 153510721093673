import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js/max';
import SignUpSubmitted from './SignUpSubmittedPage';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import executeRecaptcha from '../utils/recaptcha';

const SignUpForm = ({ show, handleClose, selectedPackage, selectedProviderCategory, address, latitude, longitude, techClass_name, coverageCheck }) => {
  const [userType, setUserType] = useState(techClass_name === 'Business Internet' ? 'business' : 'home');
  const [userIp, setUserIp] = useState('N/A');

  // Add useEffect to fetch IP on mount
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
        setUserIp('N/A');
      }
    };

    fetchIp();
  }, []);

  const [formData, setFormData] = useState({
    installationAddress: address,
    gpsLocation: `${latitude}, ${longitude}`,
    selectedPackage: selectedPackage,
    userType: techClass_name === 'Business Internet' ? 'business' : 'home',
    selectedItemType: selectedPackage.packageVariant_type || 'regular',
    coverage_check_id: coverageCheck?.id,
    user_ip: userIp || 'N/A'
  });

  // Update formData when userIp changes
  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      user_ip: userIp
    }));
  }, [userIp]);

  const [showThankYou, setShowThankYou] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  const [sameAsInstallationAddress, setSameAsInstallationAddress] = useState(false);
  const [sameAsInstallationAddressBusiness, setSameAsInstallationAddressBusiness] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;
    let errorMessage = '';

    if (name.includes('Email')) {
      isValid = validateEmail(value);
      errorMessage = isValid ? '' : 'Please enter a valid email address';
    } else if (name.includes('Phone') || name.includes('CellularPhone')) {
      const countryCodeField = `${name}CountryCode`;
      const countryCode = formData[countryCodeField] || '+27';
      isValid = validatePhone(value, countryCode);
      errorMessage = isValid ? '' : `Please enter a valid phone number for the selected country code`;
    } else if (name.endsWith('CountryCode')) {
      const phoneFieldName = name.replace('CountryCode', '');
      const phoneNumber = formData[phoneFieldName];
      if (phoneNumber) {
        isValid = validatePhone(phoneNumber, value);
        errorMessage = isValid ? '' : `Please enter a valid phone number for the selected country code`;
      }
    }

    if (name === 'sameAsInstallationAddressBusiness') {
      setSameAsInstallationAddressBusiness(value);
      if (value) {
        setFormData(prevState => ({
          ...prevState,
          businessAddress: prevState.installationAddress
        }));
      }
      return;
    }

    if (name === 'businessPhone' || name === 'representativeCellPhone') {
      const otherField = name === 'businessPhone' ? 'representativeCellPhone' : 'businessPhone';
      const isOtherFieldEmpty = !formData[otherField];
      
      setFormData(prevState => ({
        ...prevState,
        [`${name}Error`]: isOtherFieldEmpty && !value ? 'At least one phone number is required' : '',
        [`${otherField}Error`]: isOtherFieldEmpty && !value ? 'At least one phone number is required' : ''
      }));
    }

   // console.log(`Field ${name} changed:`, { value, isValid, errorMessage });

    setFormData(prevState => {
      const newState = {
        ...prevState,
        [name]: value,
        ...(name.endsWith('CountryCode')
          ? { [`${name.replace('CountryCode', '')}Error`]: errorMessage }
          : { [`${name}Error`]: errorMessage })
      };

      // Clear errors for business phone fields if at least one is filled
      if (formData.userType === 'business' && (name === 'businessPhone' || name === 'representativeCellPhone')) {
        const otherField = name === 'businessPhone' ? 'representativeCellPhone' : 'businessPhone';
        if (value || newState[otherField]) {
          newState.businessPhoneError = '';
          newState.representativeCellPhoneError = '';
        } else {
          newState.businessPhoneError = 'At least one phone number is required';
          newState.representativeCellPhoneError = 'At least one phone number is required';
        }
      }

      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Execute reCAPTCHA verification
    const isVerified = await executeRecaptcha('signup_form');
    if (!isVerified) {
        alert('Verification failed. Please try again.');
        return;
    }

    // Get referral team member ID from cookie
    let referralTeamMemberId = Cookies.get('referral_team_member_id') || '0';
    let referralTeamMemberType = Cookies.get('referral_team_member_type') || 'N/A';
    let referralTeamMemberRtQueue = Cookies.get('referral_team_member_rt_queue') || 'N/A';
    let referralTeamMemberName = Cookies.get('referral_team_member_name') || 'N/A';
    let referralTeamMemberEmail = Cookies.get('referral_team_member_email') || 'N/A';
    let referralTeamMemberCell = Cookies.get('referral_team_member_phone') || 'N/A';
    let referralTeamMemberRtUsername = Cookies.get('referral_team_member_rt_username') || 'N/A';

    // If referralTeamMemberId is 0, get a retention team member
    if (referralTeamMemberId === '0') {
        try {

            referralTeamMemberId = '0';
            referralTeamMemberType = 'Retention';
            referralTeamMemberRtQueue = 'Retentions';
            referralTeamMemberName = 'N/A';
            referralTeamMemberEmail = 'N/A';
            referralTeamMemberCell = 'N/A';
            referralTeamMemberRtUsername = 'Trusc';
        } catch (error) {
            console.error('Error fetching retention team member:', error);
            alert('Error assigning retention team member. Please try again.');
            return;
        }
    }

    // Check for any empty required fields
    const requiredFields = Object.keys(formData).filter(key => 
      !key.endsWith('Error') && 
      !['selectedPackage', 'selectedItemType'].includes(key)
    );
    const emptyFields = requiredFields.filter(key => !formData[key]);
    
    if (emptyFields.length > 0) {
      alert(`Please fill in all required fields: ${emptyFields.join(', ')}`);
      return;
    }

    // Check for any validation errors
    const errors = Object.keys(formData).filter(key => key.endsWith('Error') && formData[key]);
    if (errors.length > 0) {
      alert(`Please correct the following errors: ${errors.map(key => formData[key]).join(', ')}`);
      return;
    }

    if (formData.userType === 'business') {
      if (!formData.businessPhone && !formData.representativeCellPhone) {
        setFormData(prevState => ({
          ...prevState,
          businessPhoneError: 'At least one phone number is required',
          representativeCellPhoneError: 'At least one phone number is required'
        }));
        return;
      }
    }

    const isBundle = 'bundle_id' in selectedPackage;
    const isPromo = selectedPackage.packageVariant_type === 'promo';

    const content = [
      'id: ticket/new',
      `Queue: ${referralTeamMemberRtQueue || process.env.REACT_APP_RT_QUEUE_NAME}`,
      `Subject: New Installation - ${selectedProviderCategory.provCat_name} - ${selectedPackage.package_name}${isPromo ? ' (Promotional)' : ''}${isBundle ? ' (Bundle)' : ''}`,
      `Owner: ${referralTeamMemberRtUsername || 'Trusc'}`,
      'Text:'
    ];

    const textContent = [
      `Coverage Check ID: ${coverageCheck?.id || 'N/A'}`,
      `User IP: ${userIp}`,
      `Referral Team Member ID: ${referralTeamMemberId}`,
      `Referral Team Member Type: ${referralTeamMemberType}`,
      `Referral Team Member Name: ${referralTeamMemberName}`,
      `Referral Team Member Email: ${referralTeamMemberEmail}`,
      `Referral Team Member Cell: ${referralTeamMemberCell}`,
     
      `User Type: ${formData.userType}`,
      formData.userType === 'business' ? `Business Name: ${formData.businessName}` : `Client Name: ${formData.clientName}`,
      formData.userType === 'business' ? `Registration Number: ${formData.registrationNumber}` : `Identity Number: ${formData.identityNumber}`,
      formData.userType === 'business' 
        ? `Business Address: ${sameAsInstallationAddressBusiness ? formData.installationAddress : formData.businessAddress}`
        : `Client Address: ${sameAsInstallationAddress ? formData.installationAddress : formData.clientAddress}`,
      `Installation Address: ${formData.installationAddress}`,
      `GPS Location: ${formData.gpsLocation}`,
      `Provider Category: ${selectedProviderCategory.provCat_name}`,
      `Package/Bundle Name: ${selectedPackage.package_name}`,
      `Download Speed: ${selectedPackage.download_speed_mb} Mbps`,
      `Upload Speed: ${selectedPackage.upload_speed_mb} Mbps`,
      `Term: ${selectedPackage.term}`,
      `Installation Cost: R${selectedPackage.packageVariant_sell_installation}`,
      `Monthly Cost: R${selectedPackage.packageVariant_sell_price}`,
      `Payment Date: ${formData.paymentDate}`,
      `Payment Method: ${formData.paymentMethod}`,
      `Item Type: ${selectedPackage.packageVariant_type || 'regular'} Package`,
      `Package ID: ${selectedPackage.package_id}`,
      `Package Variant ID: ${selectedPackage.packageVariant_id}`,
      `Promotion ID: ${selectedPackage.promotion_id || 'N/A'}`,
      'Value Added Products:',
      selectedPackage.value_added_products && selectedPackage.value_added_products.length > 0 
        ? selectedPackage.value_added_products.map(product => {
            return `- ${product.vap_name} (Quantity: ${product.vap_quantity}, Price: R${product.vap_sell})`;
          }).join('\n')
        : 'N/A'
    ];

    if (formData.userType === 'business') {
      const businessPhone = formData.businessPhone ? `Business Phone: ${formData.businessPhoneCountryCode || '+27'}${formData.businessPhone}` : null;
      const representativeCellPhone = formData.representativeCellPhone ? `Representative Cell Phone: ${formData.representativeCellPhoneCountryCode || '+27'}${formData.representativeCellPhone}` : null;
      const accountsPhone = formData.accountsPhone ? `Accounts Phone: ${formData.accountsPhoneCountryCode || '+27'}${formData.accountsPhone}` : null;
      const technicalPhone = formData.technicalPhone ? `Technical Phone: ${formData.technicalPhoneCountryCode || '+27'}${formData.technicalPhone}` : null;

      textContent.push(
        `Business Representative: ${formData.businessRepresentative}`,
        `Business Representative ID: ${formData.businessRepresentativeId}`,
        ...[businessPhone, representativeCellPhone].filter(Boolean),
        `Representative Email: ${formData.representativeEmail}`,
        `Accounts Contact: ${formData.accountsContact}`,
        ...[accountsPhone].filter(Boolean),
        `Accounts Email: ${formData.accountsEmail}`,
        `Technical Contact: ${formData.technicalContact}`,
        ...[technicalPhone].filter(Boolean),
        `Technical Email: ${formData.technicalEmail}`
      );
    } else {
      const clientPhone = formData.clientPhone ? `Client Phone: ${formData.clientPhoneCountryCode || '+27'}${formData.clientPhone}` : null;
      const clientCellularPhone = formData.clientCellularPhone ? `Client Cellular Phone: ${formData.clientCellularPhoneCountryCode || '+27'}${formData.clientCellularPhone}` : null;

      textContent.push(
        `Client Address: ${sameAsInstallationAddress ? formData.installationAddress : formData.clientAddress}`,
        ...[clientPhone, clientCellularPhone].filter(Boolean),
        `Client Email: ${formData.clientEmail}`
      );
    }

    content.push(textContent.map(line => ` ${line}`).join('\n'));

    try {
      const response = await axios.post(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/new-ticket`, 
        { content: content.join('\n') },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data.includes('created')) {
        console.log('RT Ticket created successfully');
        const ticketMatch = response.data.match(/Ticket (\d+) created/);
        if (ticketMatch) {
          const ticketNumber = ticketMatch[1];

          // Prepare DocuSeal submission data
          const getTemplateId = (userType, paymentMethod) => {
            if (userType === 'business') {
              return paymentMethod === 'debitOrder' 
                ? parseInt(process.env.REACT_APP_DOCUSEAL_TEMPLATE_ID_BUSINESS_DEBIT)
                : parseInt(process.env.REACT_APP_DOCUSEAL_TEMPLATE_ID_BUSINESS_EFT);
            }
            return paymentMethod === 'debitOrder'
              ? parseInt(process.env.REACT_APP_DOCUSEAL_TEMPLATE_ID_HOME_DEBIT)
              : parseInt(process.env.REACT_APP_DOCUSEAL_TEMPLATE_ID_HOME_EFT);
          };  

          const value_added_product_total_price = selectedPackage.value_added_products && selectedPackage.value_added_products.length > 0 
            ? parseFloat(selectedPackage.value_added_products.reduce((total, product) => 
                total + (parseFloat(product.vap_sell) || 0), 0
              ).toFixed(2))
            : 0;

          const eftMonthlyTotal = parseFloat(
            (
              (parseFloat(selectedPackage.packageVariant_sell_price) || 0) + 
              (parseFloat('35.00') || 0)
            ).toFixed(2)
          );

          const debitOrderMonthlyTotal = parseFloat(
            (parseFloat(selectedPackage.packageVariant_sell_price) || 0).toFixed(2)
          );
          
          const bundleOnceOffTotal = parseFloat(
            (
              (parseFloat(selectedPackage.packageVariant_sell_installation) || 0) + 
              (parseFloat(value_added_product_total_price) || 0)
            ).toFixed(2)
          );

          const docusealData = {
            template_id: getTemplateId(formData.userType, formData.paymentMethod),
            order: "preserved",
            submitters: [{
              email: formData.userType === 'business' ? formData.representativeEmail : formData.clientEmail,
              completed_redirect_url: `${process.env.REACT_APP_FRONTEND_URL}/order-details/${ticketNumber}`,
              external_id: ticketNumber,
              fields: formData.userType === 'business' ? [
                {
                  name: "business_name",
                  default_value: formData.businessName
                },
                {
                  name: "business_reg_number",
                  default_value: formData.registrationNumber
                },
                {
                  name: "physical_address",
                  default_value: sameAsInstallationAddressBusiness ? formData.installationAddress : formData.businessAddress
                },
                {
                  name: "business_representative",
                  default_value: formData.businessRepresentative
                },
                {
                  name: "representative_id",
                  default_value: formData.businessRepresentativeId
                }
                
              ] : [
                {
                  name: "Client_Name",
                  default_value: formData.clientName
                },
                {
                  name: "Identity_Number",
                  default_value: formData.identityNumber
                },
                {
                  name: "Client_Address",
                  default_value: sameAsInstallationAddress ? formData.installationAddress : formData.clientAddress
                }
             
              ]
            }]
          };

          // Common fields for both business and home
          docusealData.submitters[0].fields.push(
            {
              name: "Installation_Address",
              default_value: formData.installationAddress
            },
            {
              name: "GPS",
              default_value: formData.gpsLocation
            },
            {
              name: "Service_Date",
              default_value: formData.paymentDate
            },
            {
              name: "Package",
              default_value: selectedProviderCategory.provCat_name + ' - ' + selectedPackage.package_name
            },
            {
              name: "NRC_Descr_1",
              default_value: "Installation Cost"
            },
            {
              name: "NRC_QTY_1",
              default_value: "1"
            },
            {
              name: "NRC_AMT_1",
              default_value: selectedPackage.packageVariant_sell_installation
            },
            {
              name: "RC_Descr_1",
              default_value: "Monthly Cost"
            },
            {
              name: "RC_QTY_1",
              default_value: "1"
            },
            {
              name: "RC_AMT_1",
              default_value: selectedPackage.packageVariant_sell_price
            },
            {
              name: "Duration",
              default_value: selectedPackage.term
            },
            {
              name: "Sales_Representative",
              default_value: referralTeamMemberName
            }
          );

          //IF Bundle/Promo Bundle
          if (selectedPackage.value_added_products && selectedPackage.value_added_products.length > 0) {
            selectedPackage.value_added_products.forEach(product => {
              docusealData.submitters[0].fields.push({
                name: "NRC_Descr_2",
                default_value: product.vap_name
              },
              {
                name: "NRC_QTY_2",
                default_value: product.vap_quantity
              },
              {
                name: "NRC_AMT_2",
                default_value: product.vap_sell
              },
              {
                name: "NRC_Total",
                default_value: bundleOnceOffTotal
              }
            );
            });
          }

          //If Package/Promo Package (not a bundle)
          if (selectedPackage.package_id) {
            docusealData.submitters[0].fields.push({
              name: "NRC_Total",
              default_value: bundleOnceOffTotal
            });
          }

          //Fields for EFT
          if (formData.paymentMethod === 'eft') {
            docusealData.submitters[0].fields.push(
              {
              name: "RC_Total",
              default_value: eftMonthlyTotal
              },
              {
                name: "RC_Descr_2",
                default_value: "EFT Fee"
              },
              {
                name: "RC_QTY_2",
                default_value: "1"
              },
              {
                name: "RC_AMT_2",
                default_value: '35.00'
              },
              {
                name: "Payment_Method",
                default_value: "EFT"
              }
          
          );
          }

          //Fields for Debit Order
          if (formData.paymentMethod === 'debitOrder') {
            docusealData.submitters[0].fields.push(
              {
              name: "RC_Total",
              default_value: debitOrderMonthlyTotal
              },
              {
                name: "Payment_Method",
                default_value: "Debit Order"
              }
          
          );
          }

          try {
            const docusealResponse = await axios.post(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/docuseal/submit`, 
              docusealData,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            );

            const submissionId = docusealResponse.data[0].submission_id;
            
            // Prepare data for database
            const signupData = {
              coverage_check_id: coverageCheck?.id,
              rt_ticket_number: ticketNumber,
              user_ip: 'N/A',
              installation_address: formData.installationAddress,
              gps_location: formData.gpsLocation,
              payment_date: formData.paymentDate,
              payment_method: formData.paymentMethod,
              package_id: selectedPackage.package_id || null,
              bundle_id: selectedPackage.bundle_id || null,
              promo_package_id: selectedPackage.promo_package_id || null,
              promo_bundle_id: selectedPackage.promo_bundle_id || null,
              package_bundle_name: selectedPackage.package_name || selectedPackage.bundle_name,
              installation_cost: selectedPackage.packageVariant_sell_installation,
              monthly_cost: selectedPackage.packageVariant_sell_price,
              term: selectedPackage.term,
              item_type: selectedPackage.packageVariant_type || 'regular',
              value_added_products: selectedPackage.value_added_products && selectedPackage.value_added_products.length > 0 
    ? selectedPackage.value_added_products.map(product => {
        return `${product.vap_name} (Quantity: ${product.vap_quantity}, Price: R${product.vap_sell})`;
    }).join('; ')
    : 'N/A',
              value_added_product_total_price: selectedPackage.value_added_products && selectedPackage.value_added_products.length > 0 
              ? selectedPackage.value_added_products.reduce((total, product) => total + (product.vap_sell), 0)
              : 0,
              provider_category: selectedProviderCategory.provCat_name,
              promotion_category: selectedPackage.promotion_id || 'N/A',
              download_speed: selectedPackage.download_speed_mb,
              upload_speed: selectedPackage.upload_speed_mb,
              submission_id: submissionId,
              referral_team_member_id: referralTeamMemberId,
              referral_team_member_name: referralTeamMemberName,
              referral_team_member_type: referralTeamMemberType
            };

            // Add type-specific fields
            if (formData.userType === 'business') {
              Object.assign(signupData, {
                business_name: formData.businessName,
                registration_number: formData.registrationNumber,
                business_address: sameAsInstallationAddressBusiness ? formData.installationAddress : formData.businessAddress,
                business_representative: formData.businessRepresentative,
                business_representative_id: formData.businessRepresentativeId,
                business_phone: formData.businessPhone,
                business_phone_country_code: formData.businessPhoneCountryCode || '+27',
                representative_cell_phone: formData.representativeCellPhone,
                representative_cell_country_code: formData.representativeCellPhoneCountryCode || '+27',
                representative_email: formData.representativeEmail,
                accounts_contact: formData.accountsContact,
                accounts_phone: formData.accountsPhone,
                accounts_phone_country_code: formData.accountsPhoneCountryCode || '+27',
                accounts_email: formData.accountsEmail,
                technical_contact: formData.technicalContact,
                technical_phone: formData.technicalPhone,
                technical_phone_country_code: formData.technicalPhoneCountryCode || '+27',
                technical_email: formData.technicalEmail
              });

              try {
                await axios.post(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/signup/business`, signupData);
              } catch (error) {
                console.error('Error saving business signup:', error);
                // Continue with navigation even if database save fails
              }
            } else {
              Object.assign(signupData, {
                client_name: formData.clientName,
                identity_number: formData.identityNumber,
                client_address: sameAsInstallationAddress ? formData.installationAddress : formData.clientAddress,
                client_phone: formData.clientPhone,
                client_phone_country_code: formData.clientPhoneCountryCode || '+27',
                client_cellular_phone: formData.clientCellularPhone,
                client_cellular_country_code: formData.clientCellularPhoneCountryCode || '+27',
                client_email: formData.clientEmail
              });

              try {
                await axios.post(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/signup/home`, signupData);
              } catch (error) {
                console.error('Error saving home signup:', error);
                // Continue with navigation even if database save fails
              }
            }

            handleClose();
            navigate('/signup-submitted', { state: { ticketNumber } });
          } catch (error) {
            console.error('Error creating DocuSeal submission:', error);
            // Continue with database save even if DocuSeal submission fails
          }
        } else {
          alert('Ticket created successfully on RT, but ticket number could not be extracted.');
        }
      } else {
        console.error('Failed to create RT Ticket. Server response:', response.data);
        alert('Failed to create RT Ticket. Please try again or contact support.');
      }
    } catch (error) {
      console.error('Error creating RT Ticket:', error.response ? error.response.data : error.message);
      alert('An error occurred while creating the RT Ticket. Please try again or contact support.');
    }
  };

  const paymentDates = ['1st', '3rd', '5th', '7th', '10th', '15th', '20th', '25th', '27th', 'Last'];

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone, countryCode) => {
    try {
      // Remove any non-digit characters from the phone number
      const cleanPhone = phone.replace(/\D/g, '');
      
      // Remove the '+' from the country code if present
      const cleanCountryCode = countryCode.replace('+', '');
      
      // Combine the country code and phone number
      const fullNumber = `+${cleanCountryCode}${cleanPhone}`;
      
      const phoneNumber = parsePhoneNumber(fullNumber);
      return phoneNumber.isValid();
    } catch (error) {
      console.error('Error validating phone number:', error);
      return false;
    }
  };

  const getCountryCodes = () => {
    return getCountries().map(country => ({
      code: country,
      dialCode: `+${getCountryCallingCode(country)}`,
      name: new Intl.DisplayNames(['en'], { type: 'region' }).of(country)
    })).sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton style={{
        backgroundColor: '#003CA6',
        color: 'white',
        textShadow: '1px 1px 2px black'
      }}>
       <Modal.Title>
  Signing Up: {selectedProviderCategory.provCat_name} - {selectedPackage.package_name}
  
</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {techClass_name === 'Business Internet' ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Business Name *</Form.Label>
                <Form.Control type="text" name="businessName" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Registration Number *</Form.Label>
                <Form.Control type="text" name="registrationNumber" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Business Representative Name*</Form.Label>
                <Form.Control type="text" name="businessRepresentative" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Business Representative ID*</Form.Label>
                <Form.Control type="text" name="businessRepresentativeId" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Business address is the same as installation address"
                  checked={sameAsInstallationAddressBusiness}
                  onChange={(e) => setSameAsInstallationAddressBusiness(e.target.checked)}
                />
              </Form.Group>
              {!sameAsInstallationAddressBusiness && (
                <Form.Group className="mb-3">
                  <Form.Label>Business Address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessAddress"
                    onChange={handleInputChange}
                    required={!sameAsInstallationAddressBusiness}
                  />
                </Form.Group>
              )}
              <Form.Text className="text-muted mb-3">
                Either a Business Phone or a Representative Cell Phone is required as minimum.
              </Form.Text>
              <Form.Group className="mb-3">
                <Form.Label>Business Phone</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="businessPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.businessPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="businessPhone" 
                    onChange={handleInputChange} 
                    isInvalid={!!formData.businessPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.businessPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Representative Cell Phone</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="representativeCellPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.representativeCellPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="representativeCellPhone" 
                    onChange={handleInputChange} 
                    isInvalid={!!formData.representativeCellPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.representativeCellPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Representative Email *</Form.Label>
                <Form.Control type="email" name="representativeEmail" onChange={handleInputChange} required />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Client Name *</Form.Label>
                <Form.Control type="text" name="clientName" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Identity Number *</Form.Label>
                <Form.Control type="text" name="identityNumber" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Home Address is the same as installation address"
                  checked={sameAsInstallationAddress}
                  onChange={(e) => setSameAsInstallationAddress(e.target.checked)}
                />
              </Form.Group>
              {!sameAsInstallationAddress && (
                <Form.Group className="mb-3">
                  <Form.Label>Client Address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="clientAddress"
                    onChange={handleInputChange}
                    required={!sameAsInstallationAddress}
                  />
                </Form.Group>
              )}
              <Form.Text className="text-muted mb-3">
                Either a Client Phone or a Client Cellular Phone is required as minimum.
              </Form.Text>
              <Form.Group className="mb-3">
                <Form.Label>Client Phone</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="clientPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.clientPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="clientPhone" 
                    onChange={handleInputChange} 
                    isInvalid={!!formData.clientPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.clientPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Client Cellular Phone</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="clientCellularPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.clientCellularPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="clientCellularPhone" 
                    onChange={handleInputChange} 
                    isInvalid={!!formData.clientCellularPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.clientCellularPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
             
              <Form.Group className="mb-3">
                <Form.Label>Client Email *</Form.Label>
                <Form.Control 
                  type="email" 
                  name="clientEmail" 
                  onChange={handleInputChange} 
                  required 
                  isInvalid={!!formData.clientEmailError}
                />
                <Form.Control.Feedback type="invalid">
                  {formData.clientEmailError || 'This field is required'}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Installation Address *</Form.Label>
            <Form.Control 
              type="text" 
              name="installationAddress" 
              value={formData.installationAddress} 
              onChange={handleInputChange} 
              required 
            />
          </Form.Group>
          

          <Form.Group className="mb-3">
            <Form.Label>Regular Payment Date *</Form.Label>
            <Row>
              {paymentDates.map((date, index) => (
                <Col key={index} xs={6} sm={4} md={3} lg={2}>
                  <Form.Check
                    type="radio"
                    id={`payment-date-${date}`}
                    label={date}
                    name="paymentDate"
                    value={date}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Payment Method *</Form.Label>
            <Form.Select name="paymentMethod" onChange={handleInputChange} required>
              <option value="">Select a payment method</option>
              <option value="eft">EFT Processing Fee (R35)</option>
              <option value="debitOrder">Unpaid Debit Order Fee (R50)</option>
            </Form.Select>
          </Form.Group>

          {techClass_name === 'Business Internet' && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Accounts Contact Name *</Form.Label>
                <Form.Control type="text" name="accountsContact" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Accounts Phone *</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="accountsPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.accountsPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="accountsPhone" 
                    onChange={handleInputChange} 
                    required 
                    isInvalid={!!formData.accountsPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.accountsPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Accounts Email *</Form.Label>
                <Form.Control type="email" name="accountsEmail" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Technical Contact Name *</Form.Label>
                <Form.Control type="text" name="technicalContact" onChange={handleInputChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Technical Phone *</Form.Label>
                <div className="d-flex">
                  <Form.Select 
                    className="me-2 max-w" 
                    name="technicalPhoneCountryCode" 
                    onChange={handleInputChange}
                    value={formData.technicalPhoneCountryCode || '+27'}
                  >
                    {getCountryCodes().map(country => (
                      <option key={country.code} value={country.dialCode}>
                        {country.dialCode} ({country.name})
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control 
                    type="tel" 
                    name="technicalPhone" 
                    onChange={handleInputChange} 
                    required 
                    isInvalid={!!formData.technicalPhoneError}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {formData.technicalPhoneError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Technical Email *</Form.Label>
                <Form.Control type="email" name="technicalEmail" onChange={handleInputChange} required />
              </Form.Group>
            </>
          )}

          <Button variant="trusc-primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
     
    </Modal>
  );
};

export default SignUpForm;
