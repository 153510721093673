// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.technology-page .hero-technology {
    background-color: #003CA6 !important;
    max-width: 1250px;
    margin: 0 auto;
  }
  
  .technology-page .technology-classes img {
   
    object-fit: cover;
  }
  
  .technology-page .cta {
    background-color: #f8f9fa;
  }

.technology-page .tech-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.technology-page .tech-card:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.technology-page .tech-card img {
  
  object-fit: fill;
  margin-bottom: 15px;
}

.technology-page .tech-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.technology-page .tech-card p {
  flex-grow: 1;
}

.technology-page .tech-card button {
  align-self: center;
}

.technology-classes
{
    max-width: 1280px;
    margin: 0 auto;
}

@media (max-width: 767px) {
  .technology-page .tech-card {
    margin-bottom: 20px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/pages/TechnologyPage.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,cAAc;EAChB;;EAEA;;IAEE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;IAEI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".technology-page .hero-technology {\r\n    background-color: #003CA6 !important;\r\n    max-width: 1250px;\r\n    margin: 0 auto;\r\n  }\r\n  \r\n  .technology-page .technology-classes img {\r\n   \r\n    object-fit: cover;\r\n  }\r\n  \r\n  .technology-page .cta {\r\n    background-color: #f8f9fa;\r\n  }\r\n\r\n.technology-page .tech-card {\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  padding: 20px;\r\n  border: 1px solid #e0e0e0;\r\n  border-radius: 8px;\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n.technology-page .tech-card:hover {\r\n  box-shadow: 0 5px 15px rgba(0,0,0,0.1);\r\n}\r\n\r\n.technology-page .tech-card img {\r\n  \r\n  object-fit: fill;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.technology-page .tech-card h2 {\r\n  font-size: 1.5rem;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.technology-page .tech-card p {\r\n  flex-grow: 1;\r\n}\r\n\r\n.technology-page .tech-card button {\r\n  align-self: center;\r\n}\r\n\r\n.technology-classes\r\n{\r\n    max-width: 1280px;\r\n    margin: 0 auto;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n  .technology-page .tech-card {\r\n    margin-bottom: 20px;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
