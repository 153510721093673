import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NetworkNotices.css';

const NetworkNotices = () => {
  const [notices, setNotices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNotice, setExpandedNotice] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch data in parallel
        const [noticesRes, updatesRes, locationsRes, statusesRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/network-notice`),
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/network-notice-update`),
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/affected-locations`),
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/notice-status`)
        ]);
        
        // Filter only active notices
        const activeNotices = noticesRes.data.filter(notice => 
          notice.networkNotice_status === 'active'
        );
        
        // Add updates to notices
        const noticesWithUpdates = activeNotices.map(notice => ({
          ...notice,
          updates: updatesRes.data
            .filter(update => update.networkNotice_id === notice.networkNotice_id && update.networkNoticeUpdate_status === 'active')
            .sort((a, b) => new Date(b.networkNoticeUpdate_date) - new Date(a.networkNoticeUpdate_date))
        }));
        
        // Sort notices by date in descending order (newest first)
        const sortedNotices = noticesWithUpdates.sort((a, b) => 
          new Date(b.networkNotice_date) - new Date(a.networkNotice_date)
        );
        
        setNotices(sortedNotices);
        setLocations(locationsRes.data);
        setStatuses(statusesRes.data);
      } catch (err) {
        console.error('Error fetching network notices data:', err);
        setError('Unable to load network notices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    
    // Refresh data every 5 minutes
    const intervalId = setInterval(fetchData, 300000);
    return () => clearInterval(intervalId);
  }, []);
  
  const getStatusClass = (statusId) => {
    const status = statuses.find(s => s.noticeStatus_id === statusId);
    if (!status) return 'status-unknown';
    
    switch (status.noticeStatus_name.toLowerCase()) {
      case 'pending':
        return 'status-pending';
      case 'in progress':
        return 'status-in-progress';
      case 'resolved':
        return 'status-resolved';
      case 'delayed':
        return 'status-delayed';
      default:
        return 'status-unknown';
    }
  };
  
  const getLocationName = (locationId) => {
    const location = locations.find(l => l.affectedLocations_id === locationId);
    return location ? location.affectedLocations_locName : 'Unknown Location';
  };
  
  const getStatusName = (statusId) => {
    const status = statuses.find(s => s.noticeStatus_id === statusId);
    return status ? status.noticeStatus_name : 'Unknown Status';
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleString('en-ZA', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  const toggleNoticeExpansion = (noticeId) => {
    setExpandedNotice(expandedNotice === noticeId ? null : noticeId);
  };
  
  const filterNotices = () => {
    if (activeFilter === 'all') {
      return notices;
    }
    
    return notices.filter(notice => {
      const status = statuses.find(s => s.noticeStatus_id === notice.noticeStatus_status_id);
      return status && status.noticeStatus_name.toLowerCase() === activeFilter.toLowerCase();
    });
  };
  
  const filteredNotices = filterNotices();

  if (loading) {
    return (
      <div className="network-notices-container">
        <div className="network-notices-loading">
          <div className="spinner"></div>
          <p>Loading network notices...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="network-notices-container">
        <div className="network-notices-error">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Try Again</button>
        </div>
      </div>
    );
  }

  return (
    <div className="network-notices-container">
      <div className="network-notices-header">
        <h2>Network Status</h2>
        <p>Get real-time updates about our network status and any ongoing incidents.</p>
      </div>
      
      <div className="network-notices-filters">
        <button
          className={activeFilter === 'all' ? 'active' : ''}
          onClick={() => setActiveFilter('all')}
        >
          All Notices
        </button>
        <button
          className={activeFilter === 'In Progress' ? 'active' : ''}
          onClick={() => setActiveFilter('In Progress')}
        >
          In Progress
        </button>
        <button
          className={activeFilter === 'Pending' ? 'active' : ''}
          onClick={() => setActiveFilter('Pending')}
        >
          Pending
        </button>
        <button
          className={activeFilter === 'Resolved' ? 'active' : ''}
          onClick={() => setActiveFilter('Resolved')}
        >
          Resolved
        </button>
      </div>
      
      {filteredNotices.length === 0 ? (
        <div className="network-notices-empty">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
          <p>All systems operational</p>
          <span>There are no active network notices at this time.</span>
        </div>
      ) : (
        <div className="network-notices-list">
          {filteredNotices.map(notice => (
            <div 
              key={notice.networkNotice_id} 
              className={`network-notice-card ${expandedNotice === notice.networkNotice_id ? 'expanded' : ''}`}
            >
              <div 
                className="network-notice-summary" 
                onClick={() => toggleNoticeExpansion(notice.networkNotice_id)}
              >
                <div className="network-notice-header">
                  <div className={`status-indicator ${getStatusClass(notice.noticeStatus_status_id)}`}>
                    {getStatusName(notice.noticeStatus_status_id)}
                  </div>
                  <div className="network-notice-date">
                    {formatDate(notice.networkNotice_date)}
                  </div>
                </div>
                
                <h3 className="network-notice-title">{notice.networkNotice_title}</h3>
                <div className="network-notice-location">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                  <span>{getLocationName(notice.affectedLocations_id)}</span>
                </div>
                
                <div className="expand-indicator">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points={expandedNotice === notice.networkNotice_id ? "18 15 12 9 6 15" : "6 9 12 15 18 9"}></polyline>
                  </svg>
                </div>
              </div>
              
              {expandedNotice === notice.networkNotice_id && (
                <div className="network-notice-details">
                  <div 
                    className="network-notice-description"
                    dangerouslySetInnerHTML={{ __html: notice.networkNotice_description }}
                  />
                  
                  {notice.updates && notice.updates.length > 0 && (
                    <div className="network-notice-updates">
                      <h4>Updates</h4>
                      <div className="updates-timeline">
                        {notice.updates.map(update => (
                          <div key={update.networkNoticeUpdate_id} className="update-item">
                            <div className="update-time">{formatDate(update.networkNoticeUpdate_date)}</div>
                            <div 
                              className="update-content"
                              dangerouslySetInnerHTML={{ __html: update.networkNoticeUpdate_description }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NetworkNotices;
