import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import executeRecaptcha from '../utils/recaptcha';
import './CoverageMap.css';

const convertDMSToDD = (degrees, minutes, seconds, direction) => {
    let dd = degrees + minutes/60 + seconds/3600;
    if (direction === 'S' || direction === 'W') {
        dd = dd * -1;
    }
    return dd;
};

const parseCoordinates = (input) => {
    // Handle decimal coordinates (e.g., -32.953990, 19.048743)
    const decimalRegex = /^-?\d+\.?\d*,\s*-?\d+\.?\d*$/;
    if (decimalRegex.test(input)) {
        const [lat, lng] = input.split(',').map(coord => parseFloat(coord.trim()));
        return { lat, lng };
    }

    // Handle DMS coordinates (e.g., 32°57'14.4"S 19°02'55.5"E)
    const dmsRegex = /(\d+)°(\d+)'(\d+\.?\d*)"([NS])\s+(\d+)°(\d+)'(\d+\.?\d*)"([EW])/;
    const dmsMatch = input.match(dmsRegex);
    if (dmsMatch) {
        const [_, latDeg, latMin, latSec, latDir, lngDeg, lngMin, lngSec, lngDir] = dmsMatch;
        const lat = convertDMSToDD(
            parseFloat(latDeg),
            parseFloat(latMin),
            parseFloat(latSec),
            latDir
        );
        const lng = convertDMSToDD(
            parseFloat(lngDeg),
            parseFloat(lngMin),
            parseFloat(lngSec),
            lngDir
        );
        return { lat, lng };
    }

    return null;
};

const CoverageMap = () => {
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [address, setAddress] = useState('');
    const [results, setResults] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [promoDetails, setPromoDetails] = useState(null);
    const [mapInitialized, setMapInitialized] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const navigate = useNavigate();
    
    const addressInputRef = useRef(null);
    const [bannerData, setBannerData] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('package_id');
    const bundleId = urlParams.get('bundle_id');
    const promoPackageId = urlParams.get('promo_package_id');
    const promoBundleId = urlParams.get('promo_bundle_id');

    const selectedId = packageId || bundleId || promoPackageId || promoBundleId;

    useEffect(() => {
        const fetchPromoDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-display/promotions`);
                
                if (promoPackageId && response.data.promotionalPackages) {
                    const foundPackage = response.data.promotionalPackages.find(
                        p => p.promotional_package_id === parseInt(promoPackageId)
                    );
                    if (foundPackage) {
                        setPromoDetails({
                            type: 'Package',
                            name: foundPackage.package_name || foundPackage.Package?.package_name,
                            providerName: foundPackage.Package?.ProviderCategory?.prov_cat_name || 'N/A'
                        });
                    }
                } else if (promoBundleId && response.data.promotionalBundles) {
                    const foundBundle = response.data.promotionalBundles.find(
                        b => b.bundle_id === parseInt(promoBundleId)
                    );
                    if (foundBundle) {
                        setPromoDetails({
                            type: 'Bundle',
                            name: foundBundle.bundle_name,
                            providerName: foundBundle.ProviderCategory?.prov_cat_name || 'N/A'
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching promo details:', error);
            }
        };

        if (promoPackageId || promoBundleId) {
            fetchPromoDetails();
        }
    }, [promoPackageId, promoBundleId]);

    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/website-banners`);
                const activeBanner = response.data.find(banner => banner.banner_status === 'active');
                if (activeBanner) {
                    setBannerData(activeBanner);
                }
            } catch (error) {
                console.error('Error fetching banner data:', error);
            }
        };
        fetchBannerData();
    }, []);

    const initializeMap = () => {
        if (!window.google || mapInitialized) return;
        
        const initialLocation = { lat: -26.2041, lng: 28.0473 }; // Johannesburg
        const googleMap = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 15,
            center: initialLocation,
            disableDefaultUI: false,
            zoomControl: true,
            mapTypeControl: true,
            fullscreenControl: false
        });

        const googleMarker = new window.google.maps.Marker({
            position: initialLocation,
            map: googleMap,
            draggable: true,
        });

        const geocoder = new window.google.maps.Geocoder();

        // Add click listener to map to move marker
        googleMap.addListener("click", (event) => {
            const clickedLocation = event.latLng;
            googleMarker.setPosition(clickedLocation);
            
            // Update address field with the new location
            geocoder.geocode({ location: clickedLocation }, (results, status) => {
                if (status === "OK" && results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    setAddress(`${clickedLocation.lat()}, ${clickedLocation.lng()}`);
                }
            });
        });

        // Existing marker drag listener
        googleMarker.addListener("dragend", (event) => {
            const latLng = event.latLng;
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    setAddress(`${latLng.lat()}, ${latLng.lng()}`);
                }
            });
        });

        setMap(googleMap);
        setMarker(googleMarker);
        setMapInitialized(true);
        
        // Instead of having the overlay inside the map, make the map smaller and put controls above it
    };

    useEffect(() => {
        if (showMap) {
            // Initialize map when showing map view
            setTimeout(() => {
                initializeMap();
            }, 100);
        }
    }, [showMap]);

    useEffect(() => {
        if (map && marker && showMap) {
            const coordinates = parseCoordinates(address);
            if (coordinates) {
                const latLng = new window.google.maps.LatLng(coordinates.lat, coordinates.lng);
                map.setCenter(latLng);
                marker.setPosition(latLng);
            }
        }
    }, [address, map, marker, showMap]);

    useEffect(() => {
        if (map && marker && showMap && addressInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
            autocomplete.bindTo("bounds", map);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    map.setCenter(place.geometry.location);
                    marker.setPosition(place.geometry.location);
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [map, marker, showMap]);

    const checkCoverage = async () => {
        setIsLoading(true);
        
        // If we're in hero view, switch to map view first
        if (!showMap) {
            setShowMap(true);
            // Wait a short time for the map to initialize
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        
        // Execute reCAPTCHA verification
        const isVerified = await executeRecaptcha('coverage_check');
        if (!isVerified) {
            setIsLoading(false);
            alert('Verification failed. Please try again.');
            return;
        }

        try {
            let coordinates;
            const parsedCoords = parseCoordinates(address);
            
            if (parsedCoords) {
                // Use parsed coordinates
                coordinates = {
                    latitude: parsedCoords.lat,
                    longitude: parsedCoords.lng,
                    address: address
                };
            } else {
                // Use marker position if no valid coordinates in address
                const position = marker.getPosition();
                coordinates = {
                    latitude: position.lat(),
                    longitude: position.lng(),
                    address: address
                };
            }

            const response = await fetch(`${process.env.REACT_APP_COVERAGE_CHECK_URL}/checkCoverage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(coordinates),
            });

            const resultsData = await response.json();
            console.log('Coverage check API response:', resultsData);
            handleCoverageResponse(resultsData);
        } catch (error) {
            console.error('Error checking coverage:', error);
            setResults('Error checking coverage. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCoverageResponse = (resultsData) => {
        console.log('Handling coverage response:', resultsData);
        console.log('Coverage status:', resultsData?.coverage_check?.status);
        
        if (resultsData && resultsData.coverage_check && resultsData.coverage_check.status === "available") {
            console.log('Coverage available, preparing to navigate');
            if (selectedId) {
                const navigateUrl = `/packages?package_id=${packageId || ''}&bundle_id=${bundleId || ''}&promo_package_id=${promoPackageId || ''}&promo_bundle_id=${promoBundleId || ''}`;
                console.log('Navigating to:', navigateUrl);
                navigate(navigateUrl);
            } else {
                navigate('/packages-display', { 
                    state: { coverageData: resultsData } 
                });
            }
        } else {
            console.log('Coverage not available, showing message');
            setResults('Sorry, we do not currently provide service at this location.');
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            setIsLoading(true);
            
            // If in hero view, switch to map view first
            if (!showMap) {
                setShowMap(true);
                // Short delay to ensure map is initialized
                setTimeout(() => {
                    requestUserLocation();
                }, 500);
            } else {
                requestUserLocation();
            }
        } else {
            alert("Geolocation is not supported by your browser. Please enter an address manually.");
        }
    };
    
    const requestUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const latLng = new window.google.maps.LatLng(latitude, longitude);
                
                if (map && marker) {
                    map.setCenter(latLng);
                    marker.setPosition(latLng);

                    // Use Geocoding API to get the address
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            setAddress(results[0].formatted_address);
                        } else {
                            setAddress(`${latitude}, ${longitude}`);
                        }
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                }
            },
            (error) => {
                console.error("Error getting user location:", error);
                alert("Unable to retrieve your location. Please enter an address manually.");
                setIsLoading(false);
            }
        );
    };
    
    const handleAddressInputFocus = () => {
        if (!showMap) {
            setShowMap(true);
        }
    };
    
    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        if (!showMap && e.target.value.length > 0) {
            setShowMap(true);
        }
    };

    return (
        <div className={`coverage-container ${showMap ? 'map-view' : 'hero-view'}`}>
            {!showMap ? (
                <div className="hero-container" style={{
                    backgroundImage: bannerData ? 
                        `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.REACT_APP_IMAGE_URL}/${bannerData.banner_image})` :
                        'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(/src/assets/internet-fiber-background.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    <div className="hero-content">
                        <h1 className="hero-title" style={{ color: bannerData?.banner_title_text_color  }}>
                            {bannerData?.banner_title || 'Need RELIABLE & FAST internet?'}
                        </h1>
                        <div 
                            className="hero-subtitle" 
                            style={{ color: bannerData?.banner_content_text_color  }}
                            dangerouslySetInnerHTML={{ __html: bannerData?.banner_content || 'TRUSC has you covered.' }}
                        />
                      
                        
                        <div className="search-container">
                            <input
                                ref={addressInputRef}
                                type="text"
                                className="hero-input"
                                placeholder="Enter your address or Coordinates"
                                value={address}
                                onChange={handleAddressChange}
                                onFocus={handleAddressInputFocus}
                            />
                            <button 
                                className="check-coverage-btn" 
                                onClick={checkCoverage}
                                disabled={isLoading}
                            >
                                Check Coverage {isLoading ? 
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
                                    : <span>→</span>}
                            </button>
                        </div>
                        
                    </div>
                </div>
            ) : (
                <div className="map-container">
                    {promoDetails && (
                        <div className="alert alert-info text-center mb-4">
                            <h4 className="mb-0">
                                Check Service Availability For {promoDetails.providerName} {promoDetails.name} Promotional {promoDetails.type}
                            </h4>
                        </div>
                    )}
                    
                    {/* Controls OUTSIDE the map */}
                    <div className="map-controls-container">
                        <button 
                            className="location-btn" 
                            onClick={getUserLocation}
                            disabled={isLoading}
                            title="Use current location"
                        >
                            <i className="fas fa-location-arrow"></i>
                        </button>
                        
                        <div className="search-container map-search">
                            <input
                                ref={addressInputRef}
                                type="text"
                                className="hero-input"
                                placeholder="Enter your address or Coordinates"
                                value={address}
                                onChange={handleAddressChange}
                            />
                            <button 
                                className="check-coverage-btn" 
                                onClick={checkCoverage}
                                disabled={isLoading}
                            >
                                Check Coverage {isLoading ? 
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
                                    : <span>→</span>}
                            </button>
                        </div>
                    </div>
                    
                    {/* Map without the overlay inside */}
                    <div id="map" style={{ 
                        height: '500px',
                        width: '100%',
                        borderRadius: '8px', 
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
                        marginBottom: '20px'
                    }}></div>
                    
                    {results && (
                        <div className="alert alert-info mt-3">
                            {results}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CoverageMap;