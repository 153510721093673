import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/trusc_logo.webp';
import './NavBar.css';

const NavBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    document.body.classList.add('has-fixed-nav');
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('has-fixed-nav');
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {isMobile ? (
          <>
            <Link to="/" className="navbar-logo">
              <img src={logo} alt="TRUSC Logo" />
            </Link>
            <button className="hamburger-menu" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? '✕' : '☰'}
            </button>
            {location.pathname !== '/coverage' && (
              <Link to="/coverage" className="btn btn-trusc-primary mobile-check-coverage">Check Coverage</Link>
            )}
          </>
        ) : (
          <>
            <Link to="/" className="navbar-logo">
              <img src={logo} alt="TRUSC Logo" />
            </Link>
            <div className="nav-menu-container">
              <ul className="nav-menu">
                <li><Link to="/" className="nav-link">Home</Link></li>
                <li><Link to="/technology" className="nav-link">Technology</Link></li>
                {/*<li><Link to="/about" className="nav-link">About</Link></li>*/}
                <li><Link to="/contact" className="nav-link">Contact</Link></li>
              </ul>
            </div>
            {location.pathname !== '/coverage' && (
              <Link to="/coverage" className="btn btn-trusc-primary">Check Coverage</Link>
            )}
          </>
        )}
      </div>
      {isMobile && (
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className="nav-menu">
            <li><Link to="/" className="nav-link" onClick={toggleMobileMenu}>Home</Link></li>
            <li><Link to="/technology" className="nav-link" onClick={toggleMobileMenu}>Technology</Link></li>
            <li><Link to="/about" className="nav-link" onClick={toggleMobileMenu}>About</Link></li>
            <li><Link to="/contact" className="nav-link" onClick={toggleMobileMenu}>Contact</Link></li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
