import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TechnologyPage.css';

const TechnologyPage = () => {
  const [technologies, setTechnologies] = useState([]);

  useEffect(() => {
    const fetchTechnologies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/technology-classes-display`);
        const sortedTechnologies = response.data.sort((a, b) => a.tech_class_priority - b.tech_class_priority);
        setTechnologies(sortedTechnologies);
      } catch (error) {
        console.error("Error fetching technology classes:", error);
      }
    };

    fetchTechnologies();
  }, []);

  return (
    <div className="technology-page">
      
        <div className="container text-center">
          <h2 className="banner-title">Our Technology Classes</h2>  
        </div>
      

      <section className="technology-classes ">
        <div className="container">
          <div className="row">
            {technologies.map((tech) => (
              <div key={tech.tech_class_id} className="col-md-6 mb-4">
                <div className="tech-card text-center">
                  <img src={process.env.REACT_APP_IMAGE_URL + '/' + tech.tech_class_banner_image} alt={tech.tech_class_name} className="img-fluid rounded shadow-lg mb-3" />
                  <h2 className="mb-3">{tech.tech_class_name}</h2>
                  <p className="lead mb-3">{tech.tech_class_description}</p>
                  {tech.tech_class_coverage === 'true' ? (
                    <a href={`/coverage?tech_class_id=${tech.tech_class_id}`} className="btn btn-trusc-primary">
                      Check Coverage
                    </a>
                  ) : (
                    <a href="/contact" className="btn btn-trusc-primary">
                      Contact Us
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

   
    </div>
  );
};

export default TechnologyPage;
