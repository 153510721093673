import React, { useState, useEffect } from 'react';
import { Container, Card, ListGroup, Alert, Form, Button, Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SignUpSuccess from './SignUpSuccess';

const TrackOrder = () => {
  const { rt_ticket_number } = useParams();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [ticketNumber, setTicketNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (rt_ticket_number) {
      fetchOrderDetails(rt_ticket_number);
    }
  }, [rt_ticket_number]);

  const fetchOrderDetails = async (ticket) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/order-status/${ticket}`);
      setOrderDetails(response.data);
    } catch (err) {
      setError('Order not found. Please check your ticket number and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate(`/order-details/${ticketNumber}`);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(amount);
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      {(!rt_ticket_number || error) ? (
        <Card className="shadow-sm">
          <Card.Body>
            <Card.Title className="text-center mb-4">Track Your Order</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Enter your ticket number</Form.Label>
                <Form.Control
                  type="text"
                  value={ticketNumber}
                  onChange={(e) => setTicketNumber(e.target.value)}
                  placeholder="Enter ticket number"
                  required
                />
              </Form.Group>
              <Button 
                type="submit" 
                variant="primary" 
                className="w-100"
                disabled={loading}
              >
                Track Order
              </Button>
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
            </Form>
          </Card.Body>
        </Card>
      ) : orderDetails && (
        
        <Card className="shadow-sm">
          
          <Card.Body>
            <Card.Title className="mb-4 p-2">
              Hi, {orderDetails.client_name || orderDetails.business_name}! Thank you for signing up with us.
            </Card.Title>
          
            
            <Card.Text className="mb-4 mt-4">
              Please see your order and payment details below.
            </Card.Text>

            <h5 className="mt-2">Contract Status</h5>
             
             <SignUpSuccess/>

            <h5 className="mt-4 pt-4">Order Details</h5>
            <strong>Provider:</strong> {orderDetails.provider_category}
            <br></br>
            <strong>Package:</strong> {orderDetails.package_bundle_name}
            <br></br>
            <strong>Package Speed:</strong> {orderDetails.download_speed}Mbps Download / {orderDetails.upload_speed} Mbps Upload
            <br></br>
            <strong>Contract Term:</strong> {orderDetails.term} months
            <br></br>
            {orderDetails.promotion_category && (
              <>
                <strong>Promotion:</strong> {orderDetails.promotion_category}
                <br></br>
              </>
            )}
            <strong>Installation:</strong> {formatCurrency(orderDetails.installation_cost)}
            {orderDetails.value_added_products && (
              <>   <br></br>
                <strong>Value Added Products:</strong> {orderDetails.value_added_products}
              </>
            )}
            <br></br>
            {orderDetails.payment_method === 'eft' && (
                <>
            <strong>Monthly Fee:</strong> {formatCurrency(orderDetails.monthly_cost)} + R35 EFT/Cash Processing Fee (Not applicable for the initial payment).
            </>
            )}

            {orderDetails.payment_method === 'debitOrder' && (
                 <>
            <strong>Monthly Fee:</strong> {formatCurrency(orderDetails.monthly_cost)}
            </>
            )}
            
            <br></br>
          
            
            

          

            <h5 className="mt-4 pt-3">Payment Details</h5>
           
                
            
                <>
                   
    
              <Alert variant="info">
              
              
                
              <strong>Initial Payment Due:</strong> {formatCurrency(orderDetails.installation_cost + orderDetails.monthly_cost + orderDetails.value_added_product_total_price)} due now for the order to proceed.
              <br></br>
                The first payment comprises the installation fee, first month's fee and any value added products. <br />The first payment must be paid via EFT or in person at any of our branches.
                <br></br>
                <br></br>
                <p className="mb-0">Bank: Absa</p>
                  <p className="mb-0">Account Name: Trusc ISP (PTY) Ltd</p>
                  <p className="mb-0">Account Number: 4106980544</p>
                  <p className="mb-0">Branch Code: 632005</p>
                  <p className="mb-0">Reference: {orderDetails.rt_ticket_number}</p>
                  <p className="mb-0">You may also pay this in person at any of our branches.</p>

                
              
              
              </Alert>
              </>

             

              <h5 className="mt-2">Service Level Agreement</h5>
              <p className="mb-0">Please note your service level agreement was emailed to {orderDetails.client_email || orderDetails.representative_email}.</p>
           
          </Card.Body>
        </Card>
      )}
    </Container>
  );


};



export default TrackOrder;
