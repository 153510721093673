// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-member-info-section {
    
    max-width: 500px;
    margin: 2rem auto;
    text-align: center;
    border: 1px solid #000;
    border-radius: 10px;
}

.team-member-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.team-member-image img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
}

.team-member-details {
    text-align: center;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0;
}

.contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    background-color: white;
    border-radius: 8px;
}

.contact-item i {
    font-size: 1.25rem;
    color: #007bff;
}

p {
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamMemberInfo.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,UAAU;IACV,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".team-member-info-section {\r\n    \r\n    max-width: 500px;\r\n    margin: 2rem auto;\r\n    text-align: center;\r\n    border: 1px solid #000;\r\n    border-radius: 10px;\r\n}\r\n\r\n.team-member-card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 0.5rem;\r\n    padding: 2rem;\r\n    background-color: #ffffff;\r\n    border-radius: 10px;\r\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\r\n}\r\n\r\n.team-member-image img {\r\n    width: 200px;\r\n    height: 200px;\r\n    object-fit: contain;\r\n    border-radius: 10px;\r\n}\r\n\r\n.team-member-details {\r\n    text-align: center;\r\n}\r\n\r\n.contact-info {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1rem;\r\n    margin-top: 0;\r\n}\r\n\r\n.contact-item {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 1rem;\r\n    padding: 0;\r\n    background-color: white;\r\n    border-radius: 8px;\r\n}\r\n\r\n.contact-item i {\r\n    font-size: 1.25rem;\r\n    color: #007bff;\r\n}\r\n\r\np {\r\n    margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
