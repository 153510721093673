import React, { useState } from 'react';
import './NewsLetter.css';

const NewsLetter = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement newsletter signup logic
    console.log('Newsletter signup:', { name, email });
    // Reset form fields
    setName('');
    setEmail('');
  };

  return (
    <div className="newsletter-container">
      <h2>Join Our Newsletter</h2>
      <p>Join our newsletter and stay up to date with our latest products and specials.</p>
      <form onSubmit={handleSubmit} className="newsletter-form">
        <input
          type="text"
          placeholder="Your Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Your Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="join-button">Join Newsletter</button>
      </form>
    </div>
  );
};

export default NewsLetter;