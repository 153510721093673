export const checkTeamMemberSaleUrl = async (saleUrl = null) => {
  try {
    //console.log('Starting checkTeamMemberSaleUrl with saleUrl:', saleUrl);
    
    // Check if cookie already exists
    const cookies = document.cookie.split(';');
    const referralCookie = cookies.find(c => c.trim().startsWith('referral_team_member_id='));
    const existingReferralId = referralCookie ? referralCookie.split('=')[1] : null;
    
    //console.log('Current cookies:', cookies);
    //console.log('Found referral cookie value:', existingReferralId);

    // If cookie exists with any value, don't change it
    if (existingReferralId !== null && existingReferralId !== '0') {
      //console.log('Valid cookie exists, skipping update');
      return;
    }

    // If we have a saleUrl, check for a matching team member first
    if (saleUrl) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/team-members-display/sales-tracking`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch team members');
      }
      
      const teamMembers = await response.json();
      //console.log('Fetched team members:', teamMembers);

      const matchingMember = teamMembers.find(member => 
        member.team_member_sale_url === saleUrl
      );

      if (matchingMember) {
        //console.log('Found matching team member:', matchingMember);
        document.cookie = `referral_team_member_id=${matchingMember.team_member_id}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_name=${matchingMember.team_member_name}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_email=${matchingMember.team_member_email}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_cell=${matchingMember.team_member_cell}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_type=${matchingMember.team_member_type}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_rt_queue=${matchingMember.team_member_rt_queue}; max-age=${7 * 24 * 60 * 60}; path=/`;
        document.cookie = `referral_team_member_rt_username=${matchingMember.team_member_rt_username}; max-age=${7 * 24 * 60 * 60}; path=/`;
        return;
      }
    }

    // Only set to 0 if no cookie exists
    if (!existingReferralId) {
      //console.log('Setting default referral ID to 0');
      document.cookie = `referral_team_member_id=0; max-age=${7 * 24 * 60 * 60}; path=/`;
    }

  } catch (error) {
    //console.error('Error checking team member sale URL:', error);
    if (!document.cookie.includes('referral_team_member_id')) {
      document.cookie = `referral_team_member_id=0; max-age=${7 * 24 * 60 * 60}; path=/`;
    }
  }
}
