const executeRecaptcha = async (action) => {
    try {
        const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
        return token ? true : false;
    } catch (error) {
        console.error('reCAPTCHA error:', error);
        return false;
    }
};

export default executeRecaptcha;