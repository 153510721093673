import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ImageModal = ({ show, handleClose, imageUrl, altText }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{altText}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <img
          src={imageUrl}
          alt={altText}
          style={{
            maxWidth: '100%',
            maxHeight: '70vh',
            objectFit: 'contain'
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;