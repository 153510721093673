// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-container {
    padding: 0px;
    max-width: 1250px;
    margin: 0 auto;
}

.news-item {
    background-color: #fff;
    border-radius: 0px;
    
    margin-bottom: 20px;
    overflow: hidden;
}

.news-title {
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    padding: 20px;
    
}

.news-content {
    padding: 15px;
    line-height: 1.5;
    text-align: center;
    font-size: 1.2em;
}


`, "",{"version":3,"sources":["webpack://./src/components/News.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;;IAElB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".news-container {\r\n    padding: 0px;\r\n    max-width: 1250px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.news-item {\r\n    background-color: #fff;\r\n    border-radius: 0px;\r\n    \r\n    margin-bottom: 20px;\r\n    overflow: hidden;\r\n}\r\n\r\n.news-title {\r\n    font-weight: bold;\r\n    font-size: 2em;\r\n    text-align: center;\r\n    padding: 20px;\r\n    \r\n}\r\n\r\n.news-content {\r\n    padding: 15px;\r\n    line-height: 1.5;\r\n    text-align: center;\r\n    font-size: 1.2em;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
