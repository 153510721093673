import React, { useState, useEffect } from 'react';
import { Container, Card, ListGroup, Alert, Spinner, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SignUpSuccess = () => {
  const { rt_ticket_number } = useParams();
  const [submissionId, setSubmissionId] = useState(null);
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [submitterSlug, setSubmitterSlug] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    let retryCount = 0;
    const maxRetries = 3;
    const retryDelay = 2000; // 2 seconds

    const fetchDocuSealDetails = async (submissionId) => {
      try {
        console.log(`Attempt ${retryCount + 1} to fetch DocuSeal details for submission:`, submissionId);
        const docusealResponse = await axios.get(
          `${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/docuseal/submission-id/${submissionId}`,
          {
            timeout: 10000 // 10 second timeout
          }
        );
        console.log('DocuSeal response:', docusealResponse.data);
        
        if (!docusealResponse.data) {
          throw new Error('No data received from DocuSeal API');
        }
        
        if (isMounted) {
          setSubmissionDetails(docusealResponse.data);
          
          if (!docusealResponse.data.submitters || !docusealResponse.data.submitters[0]) {
            throw new Error('Invalid DocuSeal response: missing submitter data');
          }
          
          setSubmitterSlug(docusealResponse.data.submitters[0].slug);

          console.log('Checking contract completion status:', {
            completed_at: docusealResponse.data.completed_at,
            status: docusealResponse.data.status,
            documents: docusealResponse.data.documents
          });

          if (docusealResponse.data.completed_at) {
            console.log('Contract is completed, preparing to update ticket');
            const submissionData = docusealResponse.data;
            const documentLinks = [
              { name: 'Audit Log', url: submissionData.audit_log_url },
              ...submissionData.documents.map(doc => ({ name: doc.name, url: doc.url }))
            ];

            console.log('Document links prepared:', documentLinks);

            const ticketData = {
              Content: `
                <h3>DocuSeal Submission Details</h3>
                <h4>Client Information:</h4>
                <ul>
                  ${submissionData.submitters[0].values
                    .map(field => `<li><strong>${field.field}:</strong> ${field.value}</li>`)
                    .join('\n')}
                </ul>

                <h4>Document Links:</h4>
                <ul>
                  ${documentLinks
                    .map(doc => `<li><a href="${doc.url}">${doc.name}</a></li>`)
                    .join('\n')}
                </ul>

                <h4>Submission Timeline:</h4>
                <ul>
                  ${submissionData.submission_events
                    .map(event => `<li>${new Date(event.event_timestamp).toLocaleString()}: ${event.event_type}</li>`)
                    .join('\n')}
                </ul>
              `,
              ContentType: "text/html"
            };

            console.log('Preparing to update ticket:', {
              url: `${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/new-ticket/update-ticket/${rt_ticket_number}`,
              ticketNumber: rt_ticket_number
            });

            try {
              console.log('Sending update request...');
              const updateResponse = await axios.post(
                `${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/new-ticket/update-ticket/${rt_ticket_number}`,
                ticketData,
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              );
              console.log('Update response:', updateResponse.data);
            } catch (error) {
              console.error('Error updating order status:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
              });
            }
          } else {
            console.log('Contract is not completed yet');
          }
        }
      } catch (err) {
        console.error(`Attempt ${retryCount + 1} failed:`, err);
        
        if (retryCount < maxRetries) {
          console.log(`Retrying in ${retryDelay/1000} seconds...`);
          retryCount++;
          setTimeout(() => fetchDocuSealDetails(submissionId), retryDelay);
        } else {
          if (isMounted) {
            if (err.code === 'ECONNABORTED') {
              setError('Request timed out. Please try again or contact support if the issue persists.');
            } else if (err.response?.status === 401) {
              setError('Authentication error with DocuSeal API. Please contact support.');
            } else if (err.response?.status === 404) {
              setError('Submission not found. Please contact support.');
            } else {
              setError(`Failed to fetch submission details after ${maxRetries} attempts. Please try again or contact support.`);
            }
          }
        }
      }
    };

    const fetchData = async () => {
      try {
        console.log('Fetching submission ID for ticket:', rt_ticket_number);
        const submissionResponse = await axios.get(
          `${process.env.REACT_APP_WS_CLIENT_SERVICE_URL}/order-status/submission-id/${rt_ticket_number}`
        );
        console.log('Submission response:', submissionResponse.data);
        
        if (isMounted) {
          const submissionId = submissionResponse.data.submission_id;
          setSubmissionId(submissionId);

          if (!submissionId) {
            throw new Error('No submission ID found');
          }

          await fetchDocuSealDetails(submissionId);
        }
      } catch (err) {
        console.error('Error fetching submission ID:', err);
        if (isMounted) {
          setError(`Failed to fetch submission details: ${err.response?.data?.error || err.message}`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [rt_ticket_number]);

  const handleSignContract = () => {
    window.open(`https://docuseal.trusc.net/s/${submitterSlug}`, '_blank');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  console.log('Current submission details:', submissionDetails);

  return (
    <Container className='mt-4'>
      <Card className="mt-4">
        <Card.Body>
          <div>
            <p>Your order is ready to complete. You need to sign the agreement in order for the order to be processed.</p>
            <p><strong>Ticket Number:</strong> {rt_ticket_number}</p>
            <p><strong>Document Submission ID:</strong> {submissionId}</p>
            <p><strong>Contract Status:</strong> {submissionDetails?.status === 'pending' ? 'Awaiting Client Completion' : submissionDetails?.status || 'Pending'}</p>
            {submissionDetails?.completed_at && submissionDetails?.documents && submissionDetails.documents.length > 0 ? (
              <div>
                <Button 
                  variant="success" 
                  onClick={() => window.open(submissionDetails.documents[0].url, '_blank')}
                  className="mt-3"
                >
                  View Signed Contract
                </Button>
              </div>
            ) : (
              <div>
                <Button 
                  variant="primary" 
                  onClick={handleSignContract}
                  disabled={!submitterSlug}
                >
                  Sign Agreement
                </Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUpSuccess;
