// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsletter-container {
    background-color: #ffffff;
    padding: 40px 20px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .newsletter-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .newsletter-container p {
    font-size: 14px;
    margin-bottom: 20px;
    color: #666;
  }
  
  .newsletter-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .newsletter-form input {
    flex: 1 1 200px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .join-button {
    flex: 1 1 200px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .join-button:hover {
    background-color: #0052a3;
  }
  
  @media (min-width: 768px) {
    .newsletter-form {
      flex-wrap: nowrap;
    }
  
    .join-button {
      flex: 0 0 auto;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/NewsLetter.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,eAAe;IACf,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,cAAc;IAChB;EACF","sourcesContent":[".newsletter-container {\r\n    background-color: #ffffff;\r\n    padding: 40px 20px;\r\n    text-align: center;\r\n    max-width: 600px;\r\n    margin: 0 auto;\r\n  }\r\n  \r\n  .newsletter-container h2 {\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n    color: #333;\r\n  }\r\n  \r\n  .newsletter-container p {\r\n    font-size: 14px;\r\n    margin-bottom: 20px;\r\n    color: #666;\r\n  }\r\n  \r\n  .newsletter-form {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    gap: 10px;\r\n  }\r\n  \r\n  .newsletter-form input {\r\n    flex: 1 1 200px;\r\n    padding: 10px;\r\n    font-size: 14px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .join-button {\r\n    flex: 1 1 200px;\r\n    padding: 10px 20px;\r\n    font-size: 14px;\r\n    background-color: #0066cc;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n  }\r\n  \r\n  .join-button:hover {\r\n    background-color: #0052a3;\r\n  }\r\n  \r\n  @media (min-width: 768px) {\r\n    .newsletter-form {\r\n      flex-wrap: nowrap;\r\n    }\r\n  \r\n    .join-button {\r\n      flex: 0 0 auto;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
