import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">
     <div className="container text-center">
          <h2 className="banner-title">About Us</h2>  
        </div>

      <section className="our-story ">
        <div className="container content-container">
          <h2 className="text-center mb-4">Our Story</h2>
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <p>Although we pioneered the Wireless Internet Industry in South Africa in 2001, and since then have evolved into a business with a specialized approach to connectivity, what truly sets us apart from the pack is our client centricity and forward-thinking! We value people above all else.</p>
              <p>Therefore we recognize the importance of going the extra mile to provide personalized, expert guidance and service, based on 21 years of experience.</p>
            </div>
            <div className="col-md-6 text-center">
              <img src="about-1.webp" alt="Trusc team" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="our-vision bg-light content-container py-5">
        <div className="container content-container">
          <h2 className="text-center mb-4">Our Vision</h2>
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2 text-center">
              <p>We are bigger-picture-people! All of our short to long-term sustainability goals are based on community, empowerment, and upliftment, stemming from a strong sense of Social Responsibility. We recognize that there is potential in unity! In everything we do, we remain committed to our role as a proud and passionate Internet Service provider.</p>
              <p>With the vision of tomorrow, we contribute to a more sustainable future for generations to come. Trusc is a brand, a company, a service, and a lifestyle for our stakeholders, employees, clients, and competitors.</p>
            </div>
            <div className="col-md-6 order-md-1 text-center">
              <img src="about-2.webp" alt="Trusc vision" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      <section className="our-impact py-5">
        <div className="container content-container">
          <h2 className="text-center mb-4">Our Impact</h2>
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <p>For many Trusc is just someone who has been there and left things better than it was before. We are focused on maintaining and building on our citizenship and moving a purpose instead of just being a business. We invest locally, support domestically, and connect internationally. We do this with integrity and hands-on community-based intent.</p>
            </div>
            <div className="col-md-6 text-center">
              <img src="about-3.webp" alt="Trusc impact" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>


      <section className="our-services content-container py-5">
        <div className="container content-container">
          <h2 className="text-center mb-4">Our Services</h2>
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2 text-center">
              <p>We supply premium quality ICT products and services with enterprise-level guarantees. This is done with the absolute focus on the optimization of the client experience. Connecting people is at our core and a true passion. We are proud owners of the infrastructure we deliver on.</p>
              <p>Our offerings showcase our ability to supply the best service and experience to all our clients. It is for these reasons that we are proud to say that we are Trusc!</p>
            </div>
            <div className="col-md-6 order-md-1 text-center">
              <img src="about-2.webp" alt="Trusc vision" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

   

      

      <section className="cta py-5 bg-white text-black">
        <div className="container text-center content-container">
          <h2 className="mb-4">Join Us</h2>
          <p>Looking to find a great new career, an excellent partner in investment, or a company passionate about your connectivity? Reach out to us.</p>
          <a href="/contact" className="btn btn-trusc-primary btn-lg mt-3">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;