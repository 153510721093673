import React, { useState, useEffect } from 'react';
import { Container, Card, ListGroup, Alert, Spinner, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SignUpSuccess = () => {
  const { rt_ticket_number } = useParams();
  const [submissionId, setSubmissionId] = useState(null);
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [submitterSlug, setSubmitterSlug] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const submissionResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/order-status/submission-id/${rt_ticket_number}`
        );
        const submissionId = submissionResponse.data.submission_id;
        const orderStatus = submissionResponse.data.order_status;
        setSubmissionId(submissionId);

        const docusealResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/docuseal/submission-id/${submissionId}`
        );
        setSubmissionDetails(docusealResponse.data);
        setSubmitterSlug(docusealResponse.data.submitters[0].slug);

        if (docusealResponse.data.completed_at && orderStatus !== 'contract_signed') {
          const submissionData = docusealResponse.data;
          const documentLinks = [
            { name: 'Audit Log', url: submissionData.audit_log_url },
            ...submissionData.documents.map(doc => ({ name: doc.name, url: doc.url }))
          ];

          const ticketData = {
           
            Content: `
              <h3>DocuSeal Submission Details</h3>
              <h4>Client Information:</h4>
              <ul>
                ${submissionData.submitters[0].values
                  .map(field => `<li><strong>${field.field}:</strong> ${field.value}</li>`)
                  .join('\n')}
              </ul>

              <h4>Document Links:</h4>
              <ul>
                ${documentLinks
                  .map(doc => `<li><a href="${doc.url}">${doc.name}</a></li>`)
                  .join('\n')}
              </ul>

              <h4>Submission Timeline:</h4>
              <ul>
                ${submissionData.submission_events
                  .map(event => `<li>${new Date(event.event_timestamp).toLocaleString()}: ${event.event_type}</li>`)
                  .join('\n')}
              </ul>
            `,
            ContentType: "text/html"
          };

          await axios.post(
            `${process.env.REACT_APP_API_URL}/new-ticket/update-ticket/${rt_ticket_number}`,
            ticketData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        }
      } catch (err) {
        setError('Failed to fetch submission details');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rt_ticket_number]);

  const handleSignContract = () => {
    window.open(`https://docuseal.trusc.net/s/${submitterSlug}`, '_blank');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className='mt-4'>
      <Card className="mt-4">
        
        <Card.Body>
          <div>
            <p>You order is ready to complete. You need to sign the agreement in order for the order to be processed.</p>
            <p><strong>Ticket Number:</strong> {rt_ticket_number}</p>
            <p><strong>Document Submission ID:</strong> {submissionId}</p>
            <p><strong>Contract Status:</strong> {submissionDetails?.status || 'Pending'}</p>
            {submissionDetails?.completed_at ? (
              <></>
            ) : (
              <div>
                <Button 
                  variant="primary" 
                  onClick={handleSignContract}
                  disabled={!submitterSlug}
                >
                  Sign Agreement
                </Button>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUpSuccess;
