import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TeamMemberInfo.css';

const TeamMemberInfo = () => {
    const [teamMember, setTeamMember] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastCheckedCookie, setLastCheckedCookie] = useState('');

    useEffect(() => {
        const checkCookieAndFetch = async () => {
            const cookies = document.cookie.split(';');
            const referralCookie = cookies.find(c => c.trim().startsWith('referral_team_member_id='));
            const teamMemberId = referralCookie ? referralCookie.split('=')[1] : '0';

            if (teamMemberId !== lastCheckedCookie) {
                setLastCheckedCookie(teamMemberId);
                
                if (teamMemberId === '0') {
                    setTeamMember(null);
                    setLoading(false);
                    return;
                }

                try {
                    const response = await axios.get(`${process.env.REACT_APP_WS_MANAGEMENT_URL}/team-members/${teamMemberId}`);
                    setTeamMember(response.data);
                } catch (error) {
                    console.error('Error fetching team member:', error);
                    setTeamMember(null);
                } finally {
                    setLoading(false);
                }
            }
        };

        checkCookieAndFetch();

        const cookieCheckInterval = setInterval(checkCookieAndFetch, 1000);
        return () => clearInterval(cookieCheckInterval);
    }, [lastCheckedCookie]);

    if (loading) return null;
    if (!teamMember) return null;

    return (
        <>
            <div className="container text-center">
                <h2 className="banner-title">Your Sales Representative - {teamMember.teamMember_name}</h2>
            </div>
            
            <div className="team-member-info-section">
                <div className="team-member-card">
                    <div className="team-member-image">
                        <img src={process.env.REACT_APP_IMAGE_URL + '/' + teamMember.teamMember_image} alt={teamMember.teamMember_name} />
                        <h4>I am {teamMember.teamMember_name}, your sales representative based at {teamMember.Branch?.branch_name} Branch. Contact me if you have any queries.</h4>
                    </div>
                    <div className="team-member-details">
                        <div className="contact-info">
                            <div className="contact-item">
                                <i className="fas fa-envelope"></i>
                                <p>{teamMember.teamMember_email}</p>
                            </div>
                            <div className="contact-item">
                                <i className="fas fa-phone"></i>
                                <p>{teamMember.teamMember_phone}</p>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeamMemberInfo;