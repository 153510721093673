import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/trusc_logo.webp';
import './NavBar.css';
import axios from 'axios';

const NavBar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [networkStatus, setNetworkStatus] = useState({
    inProgress: 0,
    hasIssues: false
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    document.body.classList.add('has-fixed-nav');
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('has-fixed-nav');
    };
  }, []);

  // Fetch network status for the indicator
  useEffect(() => {
    const fetchNetworkStatus = async () => {
      try {
        setLoading(true);
        const [noticesRes, statusesRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/network-notice`),
          axios.get(`${process.env.REACT_APP_WS_CONTENT_URL}/notice-status`)
        ]);
        
        // Filter active notices
        const activeNotices = noticesRes.data.filter(notice => 
          notice.networkNotice_status === 'active'
        );
        
        // Find "In Progress" status ID
        const inProgressStatus = statusesRes.data.find(
          status => status.noticeStatus_name.toLowerCase() === 'in progress'
        );
        
        if (inProgressStatus) {
          const inProgressCount = activeNotices.filter(
            notice => notice.noticeStatus_status_id === inProgressStatus.noticeStatus_id
          ).length;
          
          setNetworkStatus({
            inProgress: inProgressCount,
            hasIssues: inProgressCount > 0
          });
        }
      } catch (error) {
        console.error('Error fetching network status:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchNetworkStatus();
    // Refresh status every 5 minutes
    const intervalId = setInterval(fetchNetworkStatus, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {isMobile ? (
          <>
            <Link to="/" className="navbar-logo">
              <img src={logo} alt="TRUSC Logo" />
            </Link>
            <button className="hamburger-menu" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? '✕' : '☰'}
            </button>
            {location.pathname !== '/coverage' && (
              <Link to="/coverage" className="btn btn-trusc-primary mobile-check-coverage">Check Coverage</Link>
            )}
          </>
        ) : (
          <>
            <Link to="/" className="navbar-logo">
              <img src={logo} alt="TRUSC Logo" />
            </Link>
            <div className="nav-menu-container">
              <ul className="nav-menu">
                <li><Link to="/" className="nav-link">Home</Link></li>
                <li><Link to="/technology" className="nav-link">Technology</Link></li>
                {/*<li><Link to="/about" className="nav-link">About</Link></li>*/}
                <li><Link to="/contact" className="nav-link">Contact</Link></li>
                <li>
                  <Link to="/network-notices" className="nav-link network-status-link">
                    Network Status
                    <span className={`network-status-indicator ${networkStatus.hasIssues ? 'has-issues' : 'all-clear'}`}>
                      {networkStatus.inProgress > 0 && networkStatus.inProgress}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            {location.pathname !== '/' && (
              <Link to="/" className="btn btn-trusc-primary">Check Coverage</Link>
            )}
          </>
        )}
      </div>
      {isMobile && (
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className="nav-menu">
            <li><Link to="/" className="nav-link" onClick={toggleMobileMenu}>Home</Link></li>
            <li><Link to="/technology" className="nav-link" onClick={toggleMobileMenu}>Technology</Link></li>
            <li><Link to="/about" className="nav-link" onClick={toggleMobileMenu}>About</Link></li>
            <li><Link to="/contact" className="nav-link" onClick={toggleMobileMenu}>Contact</Link></li>
            <li>
              <Link to="/network-notices" className="nav-link network-status-link" onClick={toggleMobileMenu}>
                Network Status
                <span className={`network-status-indicator ${networkStatus.hasIssues ? 'has-issues' : 'all-clear'}`}>
                  {networkStatus.inProgress > 0 && networkStatus.inProgress}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
